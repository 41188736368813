import React, { useContext, useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import AuthContext from "../../../context/auth/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import { toast } from "react-toastify";

const EditLandLord = () => {

  const { _t } = useTranslator();

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { isLoggedIn, user, updateProfile } = authContext;

  console.log("USER = ", user);

  const [phoneNumber, setPhoneNumber] = useState();

  console.log("Phone Number = ", phoneNumber);

  const [LandlordProfile, setLandlordProfile] = useState({
    fname_companyName: "",
    lname: "",
    cif_dni_nie: "",
    phone: "",
    linkedin_profile: "",
    type: "landlord",
  });

  const onChangeLandlord = (e) => {
    setLandlordProfile({ ...LandlordProfile, [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };

  const onPhoneChange = (value) => {
    setPhoneNumber(value);
    setLandlordProfile({ ...LandlordProfile, phone: value });
    //console.log(e.target.value);
  };
  async function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailPattern.test(email);
  }
  const onSubmitLandlord = async (e) => {
    e.preventDefault();
    // const checkemail = await isValidEmail(tenantProfile?.email)
    // if (!checkemail) {
    //   toast.error(_t("Please enter valid email format"))
    //   return;
    // }
    LandlordProfile.role = "landlord";
    console.log(LandlordProfile);
    updateProfile(user?._id, LandlordProfile);
    navigate("/landlord-profile");
  };

  const closeButton = () => {
    navigate("/landlord-profile");
  };

  useEffect(() => {
    setLandlordProfile(user);
  }, []);

  useEffect(() => {
    console.log("Landlord Profile = ", LandlordProfile);
    setPhoneNumber(LandlordProfile?.phone);
  }, [LandlordProfile]);

  return (
    <>
      {/* <div className='margin-div'></div> */}
      <div className="tabs-box-pattern">
        <div className="tabs-item d-block">
          <form onSubmit={onSubmitLandlord}>
            <div className="row">
              <div className="col-12">
                <h3 className="Property">{_t("Basic")}</h3>
              </div>

              <div className="col-sm-6 col-12">
                <div className="info-wapper">
                  <label>{_t("First Name")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder="Natash"
                    name="fname_companyName"
                    value={LandlordProfile?.fname_companyName || ""}
                    onChange={onChangeLandlord}
                  />
                </div>
              </div>
              {/* <div className="col-sm-6 col-12">
                <div className="info-wapper">
                  <label>Phone numberrrr</label>
                  <input
                    type="text"
                    className="custom-input"
                    name="phone"
                    placeholder="Enter phone number"
                    onChange={onChangeLandlord}
                    value={LandlordProfile?.phone || ""}
                  />
                </div>
              </div> */}
              <div className="col-sm-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Phone Number")}</label>
                  <PhoneInput
                    className="custom-input"
                    international
                    // defaultCountry="RU"
                    value={phoneNumber}
                    onChange={onPhoneChange}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Last Name")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter Last Name")}
                    name="lname"
                    onChange={onChangeLandlord}
                    value={LandlordProfile?.lname || ""}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="info-wapper">
                  <label>{_t("LinkedIn Profile")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter LinkedIn Profile")}
                    name="linkedin_profile"
                    onChange={onChangeLandlord}
                    value={LandlordProfile?.linkedin_profile || ""}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="row">
            <div>
              <button className="btn-fill2" onClick={onSubmitLandlord}>
                {_t("Save")}
              </button>
              <button
                className="btn-fill-border"
                onClick={closeButton}
                style={{ "margin-right": "15px" }}
              >
                {_t("Close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLandLord;
