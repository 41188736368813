import React, { Fragment, useContext, useEffect, useState } from "react";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import AuthContext from "../context/auth/AuthContext";
import LandLordContext from "../context/landlord/LandLordContext";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Loader from "../components/Loader";
import { useSnackbar } from "material-ui-snackbar-provider";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import infoicon1 from "../assets/img/info1.png";
import infoicon2 from "../assets/img/info2.png";
import infoicon3 from "../assets/img/info3.png";
import { sendUniversalMail } from "../services/sharedService";
import useTranslator from "../hooks/useTranslator";
import constants from "../Utils/constants";

const Profile = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const authContext = useContext(AuthContext);
  const { user, isLoggedIn, setLoading, loading, userType, loadUser } =
    authContext;

  const landLordContext = useContext(LandLordContext);
  const { setProperties, properties } = landLordContext;

  const [phoneNumber, setPhoneNumber] = useState("");

  const [formNum, setFormnum] = useState("profile-info");
  const [tenantProfile, setTenantProfile] = useState({
    fname: "",
    lname: "",
    email: "",
    linkedin_profile: "",
    dni_nie: "",
    passport_id: "",
    nationality: "",
    current_address: "",
    years_of_living_in_country: "",
    duration_type: "",
    livingType: "",
    phone_number: "",
    country_code: "",
    employment_status: "",
    type_of_employee: "",
    name_of_company: "",
    position: "",
    type_of_student: "",
    name_of_university: "",
    current_annual_income: "",
    rented_locally_before: "",
    email_previous_employer: "",
    email_previous_landlord: "",
  });

  console.log("tenant Profile = ", tenantProfile);

  const [LandlordProfile, setLandlordProfile] = useState({
    fname_companyName: "",
    lname: "",
    cif_dni_nie: "",
    phone: "",
    country_code: "",
    landlordtype: "",
    isLandlordInComp: "",
    company_name: "",
  });

  const [myProperties, setMyProperties] = useState([]);
  let mysingleProperty = {
    id: 0,
    address: "",
    street: "",
    flat_number: "",
    postcode: "",
    muncipality: "",
    province: "",
    landlord: user?._id,
  };

  const [formError, setFormError] = useState({
    fname: "",
    lname: "",
    cif_dni_nie: "",
    nationality: "",
    current_address: "",
    phone_number: "",
    country_code: "",
  });

  const addProperty = () => {
    // console.log("myProperties", myProperties);
    return myProperties.map((property, index) => (
      <>
        {/* {console.log(property)}
        {console.log(index)} */}

        <div className="col-12" key={index}>
          <h3 className="Property">{_t("Property")} {index + 1}</h3>
        </div>
        {/* <p>index {index}</p> */}
        <div className="col-md-6 col-12">
          <div className="info-wapper">
            <label>{_t("Property Address")}</label>
            <input
              type="text"
              className="custom-input"
              name="address"
              placeholder={_t("Enter address")}
              value={property?.address || ""}
              onChange={(e) => onChangeProperty(e, index)}
              //validations={[required, validQuestion]}
            />
          </div>
        </div>

        {/* <div className="col-md-6 col-12">
          <div className="info-wapper">
            <label>Street</label>
            <input
              type="text"
              className="custom-input"
              name="street"
              placeholder='Enter street'
              value={property?.street || ""}
              onChange={(e) => onChangeProperty(e, index)}
            //validations={[required, validQuestion]}
            />
          </div>
        </div> */}

        <div className="col-md-6 col-12">
          <div className="info-wapper">
            <label>{_t("Flat Number")}</label>
            <input
              type="text"
              className="custom-input"
              name="flat_number"
              placeholder={_t("Enter flat number")}
              value={property?.flat_number || ""}
              onChange={(e) => onChangeProperty(e, index)}
              //validations={[required, validQuestion]}
            />
          </div>
        </div>

        <div className="col-md-6 col-12">
          <div className="info-wapper">
            <label>{_t("Post Code")}</label>
            <input
              type="text"
              className="custom-input"
              name="postcode"
              placeholder={_t("Enter postcode")}
              value={property?.postcode || ""}
              onChange={(e) => onChangeProperty(e, index)}
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="info-wapper">
            <label>{_t("Municipality")} </label>
            <input
              type="text"
              className="custom-input"
              name="muncipality"
              placeholder={_t("Enter muncipality")}
              value={property?.muncipality || ""}
              onChange={(e) => onChangeProperty(e, index)}
              //validations={[required, validQuestion]}
            />
          </div>
        </div>

        <div className="col-md-6 col-12">
          <div className="info-wapper">
            <label>{_t("Province")} </label>
            <input
              type="text"
              className="custom-input"
              name="province"
              placeholder={_t("Enter province")}
              value={property?.province || ""}
              onChange={(e) => onChangeProperty(e, index)}
              //validations={[required, validQuestion]}
            />
          </div>
        </div>
      </>
    ));
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let usertype = localStorage.getItem("usertype");
    if (token && usertype) {
      loadUser(usertype);
      //console.log("HOME runs")
    } else {
      navigate("/login");
    }

    // if(user?.has_profile){
    //   navigate('/home')
    // }
  }, [isLoggedIn]);

  useEffect(() => {
    //populating the fields
    let usertype = localStorage.getItem("usertype");
    if (usertype == "landlord") {
      setLandlordProfile({ ...LandlordProfile, ...user });
    }
    if (usertype == "tenant") {
      setTenantProfile({ ...tenantProfile, ...user });
    }
  }, [user]);

  const isFormValidated = () => {
    let fields = tenantProfile;
    let errors = {};
    let formIsValid = true;
    if (fields.fname == "") {
      errors.fname = _t(" First Name is Required");
      formIsValid = false;
    }

    if (fields.lname == "") {
      errors.lname = _t(" Last Name is Required");
      formIsValid = false;
    }

    if (fields.dni_nie == "") {
      errors.dni_nie = _t(" Please Fill This Info");
      formIsValid = false;
    }

    if (fields.nationality == "") {
      errors.nationality = _t(" Nationality is Required");
      formIsValid = false;
    }

    // if (fields.current_address == "") {
    //   errors.current_address = " is Required";
    //   formIsValid = false;
    // }

    if (fields.phone_number == "") {
      errors.phone_number = _t(" Phone Number is Required");
      formIsValid = false;
    }
    // if (fields.country_code == null) {
    //   errors.country_code = "Please select country code";
    //   formIsValid = false;
    // }
    console.log(
      "COUNTRY CODE = ",
      fields.country_code,
      typeof fields.country_code
    );
    if (fields.current_annual_income == "") {
      errors.current_annual_income = _t(" Fill Your Annual Income");
      formIsValid = false;

      console.log("Seting Errors = ", errors);

      setFormError(errors);
      return formIsValid;
    }

    setFormError(errors);
    console.log(formError);

    setFormnum("profile-info");

    return formIsValid;
  };

  const onChangeTenant = (e) => {
    setTenantProfile({ ...tenantProfile, [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };

  const changePhoneNumber = (value) => {
    setPhoneNumber(value);
    setTenantProfile({ ...tenantProfile, phone_number: value });
  }

  const onSubmitTenant = (e) => {
    e.preventDefault();
    tenantProfile.role = "tenant";
    console.log(tenantProfile);

    if (isFormValidated()) {
      let userid = localStorage.getItem("saveduserid");
      console.log("userID = ", userid);
      console.log("Tentant Profile = ", tenantProfile);
      // return;
      createUserProfile(userid, tenantProfile);
      sendMailToAdmin("tenant_signup", tenantProfile?.email);

      //clearing the error
      setFormError({});
    }
  };

  const sendMailToAdmin = (emailType, emailData) => {
    let payload = {
      receiverEmail: "alisha.seraphic@gmail.com",
      senderId: emailData,
      emailType: emailType,
    };
    console.log("EMAIL PAYLOAD = ", payload);
    // return;

    sendUniversalMail(payload)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeLandlord = (e) => {
    setLandlordProfile({ ...LandlordProfile, [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };

  const changeLandlordPhoneNumber = (value) => {
    setPhoneNumber(value);
    setLandlordProfile({ ...LandlordProfile, phone: value });
  }

  const handleProperties = (e) => {
    const numProperties = parseInt(e.target.value);
    setLandlordProfile({
      ...LandlordProfile,
      no_of_properties: e.target.value,
    });
    let myPropertiesTemp = [];
    for (let i = 0; i < numProperties; i++) {
      myPropertiesTemp.push(mysingleProperty);
    }
    // console.log("myPropertiesTemp", myPropertiesTemp);
    setMyProperties(myPropertiesTemp);
  };

  const onChangeProperty = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log("indexToUpdate", index);
    setMyProperties(
      myProperties.map((item, inde) =>
        inde === index ? { ...item, [name]: value } : item
      )
    );

    //setLandlordProfile({ ...LandlordProfile, properties: myProperties });
  };

  const onSubmitLandlord = (e) => {
    e.preventDefault();
    LandlordProfile.role = "landlord";
    console.log(myProperties);
    console.log(LandlordProfile);

    createUserProfile(user?._id, LandlordProfile);
    createProperties();
    sendMailToAdmin("landlord_signup", LandlordProfile?.email);
  };

  const createUserProfile = (id, profileData) => {
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`,
        profileData,{headers:{"Accept-Language" : localStorage.getItem('i18nextLng')}}
      )
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        //toast.success("Profile Successfully Created");
        if (res.data.user.role === "tenant") {
          navigate("/kyc");
        } else {
          navigate("/home");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.error);
      });
  };

  const createProperties = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/properties/create-many`,
        myProperties,{headers:{"Accept-Language" : localStorage.getItem('i18nextLng')}}
      )
      .then((res) => {
        console.log(res.data);
        snackbar.showMessage(_t("Properties saved"));
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.msg);
      });
  };

  const [activeclassName, setActiveclassName] = useState({
    "profile-info": "active",
    "personal-info": "",
    "financial-info": "",
  });

  const changeTab = (value) => {
    setFormnum(value);
    getActiveclassName(value);
  };

  const getActiveclassName = (value) => {
    if (formNum == value) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  };

  const [countries, setCountries] = useState([
    { country: "Afghanistan", code: "+93", iso: "AF" },
    { country: "Albania", code: "+355", iso: "AL" },
    { country: "Algeria", code: "+213", iso: "DZ" },
    { country: "American Samoa", code: "+1-684", iso: "AS" },
    { country: "Andorra", code: "+376", iso: "AD" },
    { country: "Angola", code: "+244", iso: "AO" },
    { country: "Anguilla", code: "+1-264", iso: "AI" },
    { country: "Antarctica", code: "+672", iso: "AQ" },
    { country: "Antigua and Barbuda", code: "+1-268", iso: "AG" },
    { country: "Argentina", code: "+54", iso: "AR" },
    { country: "Armenia", code: "+374", iso: "AM" },
    { country: "Aruba", code: "+297", iso: "AW" },
    { country: "Australia", code: "+61", iso: "AU" },
    { country: "Austria", code: "+43", iso: "AT" },
    { country: "Azerbaijan", code: "+994", iso: "AZ" },
    { country: "Bahamas", code: "+1-242", iso: "BS" },
    { country: "Bahrain", code: "+973", iso: "BH" },
    { country: "Bangladesh", code: "+880", iso: "BD" },
    { country: "Barbados", code: "+1-246", iso: "BB" },
    { country: "Belarus", code: "+375", iso: "BY" },
    { country: "Belgium", code: "+32", iso: "BE" },
    { country: "Belize", code: "+501", iso: "BZ" },
    { country: "Benin", code: "+229", iso: "BJ" },
    { country: "Bermuda", code: "+1-441", iso: "BM" },
    { country: "Bhutan", code: "+975", iso: "BT" },
    { country: "Bolivia", code: "+591", iso: "BO" },
    { country: "Bosnia and Herzegovina", code: "+387", iso: "BA" },
    { country: "Botswana", code: "+267", iso: "BW" },
    { country: "Brazil", code: "+55", iso: "BR" },
    { country: "British Indian Ocean Territory", code: "+246", iso: "IO" },
    { country: "British Virgin Islands", code: "+1-284", iso: "VG" },
    { country: "Brunei", code: "+673", iso: "BN" },
    { country: "Bulgaria", code: "+359", iso: "BG" },
    { country: "Burkina Faso", code: "+226", iso: "BF" },
    { country: "Burundi", code: "+257", iso: "BI" },
    { country: "Cambodia", code: "+855", iso: "KH" },
    { country: "Cameroon", code: "+237", iso: "CM" },
    { country: "Canada", code: "+1", iso: "CA" },
    { country: "Cape Verde", code: "+238", iso: "CV" },
    { country: "Cayman Islands", code: "+1-345", iso: "KY" },
    { country: "Central African Republic", code: "+236", iso: "CF" },
    { country: "Chad", code: "+235", iso: "TD" },
    { country: "Chile", code: "+56", iso: "CL" },
    { country: "China", code: "+86", iso: "CN" },
    { country: "Christmas Island", code: "+61", iso: "CX" },
    { country: "Cocos Islands", code: "+61", iso: "CC" },
    { country: "Colombia", code: "+57", iso: "CO" },
    { country: "Comoros", code: "+269", iso: "KM" },
    { country: "Cook Islands", code: "+682", iso: "CK" },
    { country: "Costa Rica", code: "+506", iso: "CR" },
    { country: "Croatia", code: "+385", iso: "HR" },
    { country: "Cuba", code: "+53", iso: "CU" },
    { country: "Curacao", code: "+599", iso: "CW" },
    { country: "Cyprus", code: "+357", iso: "CY" },
    { country: "Czech Republic", code: "+420", iso: "CZ" },
    { country: "Democratic Republic of the Congo", code: "+243", iso: "CD" },
    { country: "Denmark", code: "+45", iso: "DK" },
    { country: "Djibouti", code: "+253", iso: "DJ" },
    { country: "Dominica", code: "+1-767", iso: "DM" },
    { country: "Dominican Republic", code: "+1-809, 1-829, 1-849", iso: "DO" },
    { country: "East Timor", code: "+670", iso: "TL" },
    { country: "Ecuador", code: "+593", iso: "EC" },
    { country: "Egypt", code: "+20", iso: "EG" },
    { country: "El Salvador", code: "+503", iso: "SV" },
    { country: "Equatorial Guinea", code: "+240", iso: "GQ" },
    { country: "Eritrea", code: "+291", iso: "ER" },
    { country: "Estonia", code: "+372", iso: "EE" },
    { country: "Ethiopia", code: "+251", iso: "ET" },
    { country: "Falkland Islands", code: "+500", iso: "FK" },
    { country: "Faroe Islands", code: "+298", iso: "FO" },
    { country: "Fiji", code: "+679", iso: "FJ" },
    { country: "Finland", code: "+358", iso: "FI" },
    { country: "France", code: "+33", iso: "FR" },
    { country: "French Polynesia", code: "+689", iso: "PF" },
    { country: "Gabon", code: "+241", iso: "GA" },
    { country: "Gambia", code: "+220", iso: "GM" },
    { country: "Georgia", code: "+995", iso: "GE" },
    { country: "Germany", code: "+49", iso: "DE" },
    { country: "Ghana", code: "+233", iso: "GH" },
    { country: "Gibraltar", code: "+350", iso: "GI" },
    { country: "Greece", code: "+30", iso: "GR" },
    { country: "Greenland", code: "+299", iso: "GL" },
    { country: "Grenada", code: "+1-473", iso: "GD" },
    { country: "Guam", code: "+1-671", iso: "GU" },
    { country: "Guatemala", code: "+502", iso: "GT" },
    { country: "Guernsey", code: "+44-1481", iso: "GG" },
    { country: "Guinea", code: "+224", iso: "GN" },
    { country: "Guinea-Bissau", code: "+245", iso: "GW" },
    { country: "Guyana", code: "+592", iso: "GY" },
    { country: "Haiti", code: "+509", iso: "HT" },
    { country: "Honduras", code: "+504", iso: "HN" },
    { country: "Hong Kong", code: "+852", iso: "HK" },
    { country: "Hungary", code: "+36", iso: "HU" },
    { country: "Iceland", code: "+354", iso: "IS" },
    { country: "India", code: "+91", iso: "IN" },
    { country: "Indonesia", code: "+62", iso: "ID" },
    { country: "Iran", code: "+98", iso: "IR" },
    { country: "Iraq", code: "+964", iso: "IQ" },
    { country: "Ireland", code: "+353", iso: "IE" },
    { country: "Isle of Man", code: "+44-1624", iso: "IM" },
    { country: "Israel", code: "+972", iso: "IL" },
    { country: "Italy", code: "+39", iso: "IT" },
    { country: "Ivory Coast", code: "+225", iso: "CI" },
    { country: "Jamaica", code: "+1-876", iso: "JM" },
    { country: "Japan", code: "+81", iso: "JP" },
    { country: "Jersey", code: "+44-1534", iso: "JE" },
    { country: "Jordan", code: "+962", iso: "JO" },
    { country: "Kazakhstan", code: "+7", iso: "KZ" },
    { country: "Kenya", code: "+254", iso: "KE" },
    { country: "Kiribati", code: "+686", iso: "KI" },
    { country: "Kosovo", code: "+383", iso: "XK" },
    { country: "Kuwait", code: "+965", iso: "KW" },
    { country: "Kyrgyzstan", code: "+996", iso: "KG" },
    { country: "Laos", code: "+856", iso: "LA" },
    { country: "Latvia", code: "+371", iso: "LV" },
    { country: "Lebanon", code: "+961", iso: "LB" },
    { country: "Lesotho", code: "+266", iso: "LS" },
    { country: "Liberia", code: "+231", iso: "LR" },
    { country: "Libya", code: "+218", iso: "LY" },
    { country: "Liechtenstein", code: "+423", iso: "LI" },
    { country: "Lithuania", code: "+370", iso: "LT" },
    { country: "Luxembourg", code: "+352", iso: "LU" },
    { country: "Macao", code: "+853", iso: "MO" },
    { country: "Macedonia", code: "+389", iso: "MK" },
    { country: "Madagascar", code: "+261", iso: "MG" },
    { country: "Malawi", code: "+265", iso: "MW" },
    { country: "Malaysia", code: "+60", iso: "MY" },
    { country: "Maldives", code: "+960", iso: "MV" },
    { country: "Mali", code: "+223", iso: "ML" },
    { country: "Malta", code: "+356", iso: "MT" },
    { country: "Marshall Islands", code: "+692", iso: "MH" },
    { country: "Mauritania", code: "+222", iso: "MR" },
    { country: "Mauritius", code: "+230", iso: "MU" },
    { country: "Mayotte", code: "+262", iso: "YT" },
    { country: "Mexico", code: "+52", iso: "MX" },
    { country: "Micronesia", code: "+691", iso: "FM" },
    { country: "Moldova", code: "+373", iso: "MD" },
    { country: "Monaco", code: "+377", iso: "MC" },
    { country: "Mongolia", code: "+976", iso: "MN" },
    { country: "Montenegro", code: "+382", iso: "ME" },
    { country: "Montserrat", code: "+1-664", iso: "MS" },
    { country: "Morocco", code: "+212", iso: "MA" },
    { country: "Mozambique", code: "+258", iso: "MZ" },
    { country: "Myanmar", code: "+95", iso: "MM" },
    { country: "Namibia", code: "+264", iso: "NA" },
    { country: "Nauru", code: "+674", iso: "NR" },
    { country: "Nepal", code: "+977", iso: "NP" },
    { country: "Netherlands", code: "+31", iso: "NL" },
    { country: "Netherlands Antilles", code: "+599", iso: "AN" },
    { country: "New Caledonia", code: "+687", iso: "NC" },
    { country: "New Zealand", code: "+64", iso: "NZ" },
    { country: "Nicaragua", code: "+505", iso: "NI" },
    { country: "Niger", code: "+227", iso: "NE" },
    { country: "Nigeria", code: "+234", iso: "NG" },
    { country: "Niue", code: "+683", iso: "NU" },
    { country: "North Korea", code: "+850", iso: "KP" },
    { country: "Northern Mariana Islands", code: "+1-670", iso: "MP" },
    { country: "Norway", code: "+47", iso: "NO" },
    { country: "Oman", code: "+968", iso: "OM" },
    { country: "Pakistan", code: "+92", iso: "PK" },
    { country: "Palau", code: "+680", iso: "PW" },
    { country: "Palestine", code: "+970", iso: "PS" },
    { country: "Panama", code: "+507", iso: "PA" },
    { country: "Papua New Guinea", code: "+675", iso: "PG" },
    { country: "Paraguay", code: "+595", iso: "PY" },
    { country: "Peru", code: "+51", iso: "PE" },
    { country: "Philippines", code: "+63", iso: "PH" },
    { country: "Pitcairn", code: "+64", iso: "PN" },
    { country: "Poland", code: "+48", iso: "PL" },
    { country: "Portugal", code: "+351", iso: "PT" },
    { country: "Puerto Rico", code: "+1-787, 1-939", iso: "PR" },
    { country: "Qatar", code: "+974", iso: "QA" },
    { country: "Republic of the Congo", code: "+242", iso: "CG" },
    { country: "Reunion", code: "+262", iso: "RE" },
    { country: "Romania", code: "+40", iso: "RO" },
    { country: "Russia", code: "+7", iso: "RU" },
    { country: "Rwanda", code: "+250", iso: "RW" },
    { country: "Saint Barthelemy", code: "+590", iso: "BL" },
    { country: "Saint Helena", code: "+290", iso: "SH" },
    { country: "Saint Kitts and Nevis", code: "+1-869", iso: "KN" },
    { country: "Saint Lucia", code: "+1-758", iso: "LC" },
    { country: "Saint Martin", code: "+590", iso: "MF" },
    { country: "Saint Pierre and Miquelon", code: "+508", iso: "PM" },
    { country: "Saint Vincent and the Grenadines", code: "+1-784", iso: "VC" },
    { country: "Samoa", code: "+685", iso: "WS" },
    { country: "San Marino", code: "+378", iso: "SM" },
    { country: "Sao Tome and Principe", code: "+239", iso: "ST" },
    { country: "Saudi Arabia", code: "+966", iso: "SA" },
    { country: "Senegal", code: "+221", iso: "SN" },
    { country: "Serbia", code: "+381", iso: "RS" },
    { country: "Seychelles", code: "+248", iso: "SC" },
    { country: "Sierra Leone", code: "+232", iso: "SL" },
    { country: "Singapore", code: "+65", iso: "SG" },
    { country: "Sint Maarten", code: "+1-721", iso: "SX" },
    { country: "Slovakia", code: "+421", iso: "SK" },
    { country: "Slovenia", code: "+386", iso: "SI" },
    { country: "Solomon Islands", code: "+677", iso: "SB" },
    { country: "Somalia", code: "+252", iso: "SO" },
    { country: "South Africa", code: "+27", iso: "ZA" },
    { country: "South Korea", code: "+82", iso: "KR" },
    { country: "South Sudan", code: "+211", iso: "SS" },
    { country: "Spain", code: "+34", iso: "ES" },
    { country: "Sri Lanka", code: "+94", iso: "LK" },
    { country: "Sudan", code: "+249", iso: "SD" },
    { country: "Suriname", code: "+597", iso: "SR" },
    { country: "Svalbard and Jan Mayen", code: "+47", iso: "SJ" },
    { country: "Swaziland", code: "+268", iso: "SZ" },
    { country: "Sweden", code: "+46", iso: "SE" },
    { country: "Switzerland", code: "+41", iso: "CH" },
    { country: "Syria", code: "+963", iso: "SY" },
    { country: "Taiwan", code: "+886", iso: "TW" },
    { country: "Tajikistan", code: "+992", iso: "TJ" },
    { country: "Tanzania", code: "+255", iso: "TZ" },
    { country: "Thailand", code: "+66", iso: "TH" },
    { country: "Togo", code: "+228", iso: "TG" },
    { country: "Tokelau", code: "+690", iso: "TK" },
    { country: "Tonga", code: "+676", iso: "TO" },
    { country: "Trinidad and Tobago", code: "+1-868", iso: "TT" },
    { country: "Tunisia", code: "+216", iso: "TN" },
    { country: "Turkey", code: "+90", iso: "TR" },
    { country: "Turkmenistan", code: "+993", iso: "TM" },
    { country: "Turks and Caicos Islands", code: "+1-649", iso: "TC" },
    { country: "Tuvalu", code: "+688", iso: "TV" },
    { country: "U.S. Virgin Islands", code: "+1-340", iso: "VI" },
    { country: "Uganda", code: "+256", iso: "UG" },
    { country: "Ukraine", code: "+380", iso: "UA" },
    { country: "United Arab Emirates", code: "+971", iso: "AE" },
    { country: "United Kingdom", code: "+44", iso: "GB" },
    { country: "United States", code: "+1", iso: "US" },
    { country: "Uruguay", code: "+598", iso: "UY" },
    { country: "Uzbekistan", code: "+998", iso: "UZ" },
    { country: "Vanuatu", code: "+678", iso: "VU" },
    { country: "Vatican", code: "+379", iso: "VA" },
    { country: "Venezuela", code: "+58", iso: "VE" },
    { country: "Vietnam", code: "+84", iso: "VN" },
    { country: "Wallis and Futuna", code: "+681", iso: "WF" },
    { country: "Western Sahara", code: "+212", iso: "EH" },
    { country: "Yemen", code: "+967", iso: "YE" },
    { country: "Zambia", code: "+260", iso: "ZM" },
    { country: "Zimbabwe", code: "+263", iso: "ZW" },
  ]);

  if (localStorage.getItem("usertype") === "tenant") {
    if (loading) return <Loader />;
    return (
      <>
        <form onSubmit={onSubmitTenant}>
          <div className="main-wapper height-auto bg-main-white">
            <div className="container">
              <div className="row">
                <div className="flex-wapper">
                  <div className="left-upload-wapper-2">
                    <div className="d-flex align-items-center justify-content-sm-center">
                      <div
                        className="nav flex-column nav-pills me-3 list-info-wapper"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          onClick={() => {
                            changeTab("profile-info");
                          }}
                          className={getActiveclassName("profile-info")}
                        >
                          {_t("Personal Information")}
                          <div className="circle-active ">
                            <img src={infoicon1} />{" "}
                          </div>
                        </button>

                        <button
                          onClick={() => {
                            changeTab("financial-info");
                          }}
                          className={getActiveclassName("financial-info")}
                        >
                          {_t("Professional")} & <br /> {_t("Financial Information")}
                          <div className="circle-active">
                            <img src={infoicon3} />{" "}
                          </div>
                        </button>
                      </div>
                      <div className="right-content-width">
                        <div className="tab-content" id="v-pills-tabContent">
                          {
                            formNum === "profile-info" && (
                              // <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                              <div className="right-content-info">
                                <div className="no-data-profile">
                                  <h2>
                                    {_t("We need to know a little about you before")}
                                    <br /> {_t("setting up your account")}.
                                  </h2>
                                  <p>{_t("Please enter your details")}</p>
                                </div>
                                <div className="profile-mobile">
                                  <div className="line-form">
                                    <ul className="line-flex">
                                      <li className="line-li active-2"></li>
                                      <li className="line-li"></li>
                                    </ul>
                                  </div>

                                  <h2 className="profile">
                                    {_t("Profile Information")}
                                  </h2>
                                </div>

                                <div className="row">
                                  <div className="  col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>
                                        {_t("First Name")} *
                                        <span style={{ color: "red" }}>
                                          {formError?.fname}
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="fname"
                                        placeholder={_t("Enter your name")}
                                        onChange={onChangeTenant}
                                        value={tenantProfile?.fname || ""}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>
                                        {_t("Last Name")} *
                                        <span style={{ color: "red" }}>
                                          {formError?.lname}
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="lname"
                                        placeholder={_t("Your Last Name")}
                                        onChange={onChangeTenant}
                                        value={tenantProfile?.lname || ""}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>
                                        {_t("CIF/DNI/NIE")} *
                                        <span style={{ color: "red" }}>
                                          {formError?.dni_nie}
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="dni_nie"
                                        placeholder={_t("CIF/DNI/NIE")}
                                        onChange={onChangeTenant}
                                        value={tenantProfile?.dni_nie || ""}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>{_t("If Foreigner (Passport ID)")}</label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="passport_id"
                                        placeholder={_t("passport id")}
                                        onChange={onChangeTenant}
                                        value={tenantProfile?.passport_id || ""}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>
                                        {_t("Nationality")} *
                                        <span style={{ color: "red" }}>
                                          {formError?.nationality}
                                        </span>
                                      </label>
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        name="nationality"
                                        onChange={onChangeTenant}
                                        value={tenantProfile?.nationality || ""}
                                      >
                                        <option value="null">
                                          {_t("Select your country")}
                                        </option>
                                        {countries.map((country, index) => (
                                          <option value={country?.country}>
                                            {country?.country}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>{_t("LinkedIn Profile")}</label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="linkedin_profile"
                                        placeholder={_t("Linkedin profile")}
                                        onChange={onChangeTenant}
                                        value={
                                          tenantProfile?.linkedin_profile || ""
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-6 col-12">
                                    <div>
                                      <div className="info-wapper">
                                        <label>{_t("Name of University")}</label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="name_of_university"
                                          placeholder={_t("Name Of University")}
                                          onChange={onChangeTenant}
                                          value={
                                            tenantProfile?.name_of_university ||
                                            ""
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>{_t("Highest Education")} *</label>
                                      {/* <input type="text" className="custom-input" name="type_of_employee" placeholder="type of employee" onChange={onChangeTenant} /> */}
                                      <select
                                        className="form-select"
                                        aria-label={_t("Default select example")}
                                        name="type_of_student"
                                        onChange={onChangeTenant}
                                        value={tenantProfile?.type_of_student}
                                      >
                                        <option value="">{_t("Select Degree")}</option>
                                        <option value="Undergraduate">
                                          {_t("Undergraduate")}
                                        </option>
                                        <option value="Post Graduate">
                                          {_t("Post Graduate")}
                                        </option>
                                        <option value="Others">{_t("Others")}</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-sm-3 col-6">
                                    <div className="info-wapper">
                                      <label>{_t("Duration Living In Country")}</label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="years_of_living_in_country"
                                        placeholder={_t("Duration living in country")}
                                        onChange={onChangeTenant}
                                        value={
                                          tenantProfile?.years_of_living_in_country ||
                                          ""
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-3 col-6">
                                    <div className="info-wapper">
                                      <label>{_t("Duration Type")}</label>
                                      <select
                                        className="form-select"
                                        aria-label={_t("Default select example")}
                                        name="duration_type"
                                        onChange={onChangeTenant}
                                        value={tenantProfile?.duration_type}
                                      >
                                        <option value="">
                                          {_t("Select Duration Type")}
                                        </option>
                                        <option value="months">{_t("Months")}</option>
                                        <option value="years">{_t("Years")}</option>
                                      </select>
                                    </div>
                                  </div>

                                  {/* <div className="col-sm-6 col-12">
                                  <div className="info-wapper">
                                    <label>Current Address (Employer/University Address)<span style={{ color: "red" }}>{formError?.current_address}</span></label>
                                    <input type="text" className="custom-input"
                                      name="current_address"
                                      placeholder="current address"
                                      onChange={onChangeTenant}
                                      value={tenantProfile?.current_address || ""} />
                                  </div>
                                </div> */}

                                  <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>{_t("Email")}</label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="email"
                                        placeholder={_t("Email")}
                                        onChange={onChangeTenant}
                                        value={tenantProfile?.email || ""}
                                      />
                                    </div>
                                  </div>

                                  {/* <div className="col-sm-2 col-3">
                                  <div className="info-wapper">
                                    <label>Country Code 
                                      *<span style={{ color: "red" }}>{formError?.country_code}</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example" name="country_code"
                                      onChange={onChangeTenant} value={tenantProfile?.country_code || ""}>
                                      <option value="null">+XX</option>
                                      {
                                        countries.map((country, index) => (
                                          <option value={country?.code}>{country?.country}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                </div>


                                <div className="col-sm-4 col-12">
                                  <div className="info-wapper">
                                    <label>Phone Number *<span style={{ color: "red" }}>{formError?.phone_number}</span></label>
                                    <input type="text" className="custom-input"
                                      name="phone_number"
                                      placeholder="phone number"
                                      onChange={onChangeTenant}
                                      value={tenantProfile?.phone_number || ""} />
                                  </div>
                                </div> */}

                                  <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>{_t("Phone Number")}</label>

                                      <PhoneInput
                                        className="custom-input"
                                        international
                                        // defaultCountry="RU"
                                        value={phoneNumber}
                                        // onChange={setPhoneNumber}
                                        onChange={changePhoneNumber}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <button
                                      className="btn-fill2  "
                                      onClick={() => {
                                        setFormnum("financial-info");
                                      }}
                                    >
                                      {_t("Next")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                            // </div>
                          }
                          {
                            formNum === "financial-info" && (
                              // <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                              <div className="right-content-info">
                                <div className="profile-mobile">
                                  <div className="line-form">
                                    <ul className="line-flex">
                                      <li className="line-li"></li>
                                      <li className="line-li active-2"></li>
                                    </ul>
                                  </div>

                                  <h2 className="profile">
                                    {_t("Financial Information")}
                                  </h2>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6 col-12">
                                    <div className="row">
                                      <div className=" col-12">
                                        <div className="info-wapper">
                                          <label>{_t("Employment Status")} *</label>
                                          <div className="select-radio mb-4">
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="employment_status"
                                                value={constants.employed}
                                                onChange={onChangeTenant}
                                                checked={
                                                  tenantProfile?.employment_status ==
                                                  constants.employed
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label className="form-check-label">
                                                {_t("Employed")}
                                              </label>
                                            </div>
                                            &nbsp; &nbsp;
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="employment_status"
                                                value={constants.student}
                                                onChange={onChangeTenant}
                                                checked={
                                                  tenantProfile?.employment_status ==
                                                  constants.student
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label className="form-check-label">
                                                {_t("Student")}
                                              </label>
                                            </div>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="employment_status"
                                                value={constants.unemployed}
                                                onChange={onChangeTenant}
                                                checked={
                                                  tenantProfile?.employment_status ==
                                                  constants.unemployed
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label className="form-check-label">
                                                {_t("Unemployed")}
                                              </label>
                                            </div>
                                          </div>

                                          {tenantProfile?.employment_status ==
                                            constants.employed && (
                                            <>
                                              <div className="col-12">
                                                <div className="info-wapper">
                                                  <label>
                                                    {_t("Type Of Employee")}
                                                  </label>
                                                  {/* <input type="text" className="custom-input" name="type_of_employee" placeholder="type of employee" onChange={onChangeTenant} /> */}
                                                  <select
                                                    className="form-select"
                                                    aria-label={_t("Default select example")}
                                                    name="type_of_employee"
                                                    onChange={onChangeTenant}
                                                  >
                                                    <option value="Self Employed">
                                                      {_t("Self Employed")}
                                                    </option>
                                                    <option value="Full Time">
                                                      {_t("Full Time")}
                                                    </option>
                                                    <option value="Part Time">
                                                      {_t("Part Time")}
                                                    </option>
                                                    <option value="Freelancer">
                                                      {_t("Freelancer")}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>

                                              <div className="col-12">
                                                <div
                                                  className={
                                                    tenantProfile?.employment_status ==
                                                    constants.employed
                                                      ? ""
                                                      : "disable-add"
                                                  }
                                                >
                                                  <div className="info-wapper">
                                                    <label>
                                                      {_t("Name Of Employer/Company")}
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="custom-input"
                                                      name="name_of_company"
                                                      placeholder={_t("Name of Company")}
                                                      onChange={onChangeTenant}
                                                      value={
                                                        tenantProfile?.name_of_company ||
                                                        ""
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-12">
                                                <div
                                                  className={
                                                    tenantProfile?.employment_status ==
                                                    constants.employed
                                                      ? ""
                                                      : "disable-add"
                                                  }
                                                >
                                                  <div className="info-wapper">
                                                    <label>{_t("Position")}</label>
                                                    <input
                                                      type="text"
                                                      className="custom-input"
                                                      name="position"
                                                      placeholder={_t("Enter Position")}
                                                      onChange={onChangeTenant}
                                                      value={
                                                        tenantProfile?.position ||
                                                        ""
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {tenantProfile?.employment_status ==
                                            constants.student && (
                                            <>
                                              <div className="col-12">
                                                <div className="info-wapper">
                                                  <label>{_t("Type Of Student")}</label>
                                                  {/* <input type="text" className="custom-input" name="type_of_employee" placeholder="type of employee" onChange={onChangeTenant} /> */}
                                                  <select
                                                    className="form-select"
                                                    aria-label={_t("Default select example")}
                                                    name="type_of_student"
                                                    onChange={onChangeTenant}
                                                    value={
                                                      tenantProfile?.type_of_student
                                                    }
                                                  >
                                                    <option value="Undergraduate">
                                                      {_t("Undergraduate")}
                                                    </option>
                                                    <option value="Post Graduate">
                                                      {_t("Post Graduate")}
                                                    </option>
                                                    <option value="Others">
                                                      {_t("Others")}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>

                                              <div className="col-12">
                                                <div>
                                                  <div className="info-wapper">
                                                    <label>
                                                      {_t("Name of University")}
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="custom-input"
                                                      name="name_of_university"
                                                      placeholder={_t("Name Of University")}
                                                      onChange={onChangeTenant}
                                                      value={
                                                        tenantProfile?.name_of_university ||
                                                        ""
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {tenantProfile?.employment_status ==
                                            constants.unemployed && <></>}

                                          <div className="col-12">
                                            <div className="info-wapper">
                                              <label>
                                                {_t("Current Annual Income")} *
                                                <span style={{ color: "red" }}>
                                                  {
                                                    formError?.current_annual_income
                                                  }
                                                </span>
                                              </label>
                                              <input
                                                type="number"
                                                className="custom-input"
                                                name="current_annual_income"
                                                placeholder={_t("annual income")}
                                                onChange={onChangeTenant}
                                                value={
                                                  tenantProfile?.current_annual_income ||
                                                  ""
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className=" col-sm-6 col-12">
                                    <div className="row">
                                      <div className="col-sm-12  col-12">
                                        <div className="info-wapper">
                                          <label>
                                            {_t("Have You Rented Before")} *
                                          </label>
                                          <div className="select-radio">
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="rented_locally_before"
                                                value="yes"
                                                onChange={onChangeTenant}
                                                checked={
                                                  tenantProfile?.rented_locally_before ==
                                                  "yes"
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label className="form-check-label">
                                                {_t("yes")}
                                              </label>
                                            </div>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="rented_locally_before"
                                                checked={
                                                  tenantProfile?.rented_locally_before ==
                                                  "no"
                                                    ? true
                                                    : false
                                                }
                                                value="no"
                                                onChange={onChangeTenant}
                                              />
                                              <label className="form-check-label">
                                                {_t("No")}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <p
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          color: "#212121",
                                        }}
                                      >
                                        {_t("For References")}
                                      </p>

                                      <div className="col-12">
                                        <div>
                                          <div className="info-wapper">
                                            <label>
                                              {_t("Email of Employer or University")}
                                            </label>
                                            <input
                                              type="text"
                                              className="custom-input"
                                              name="email_previous_employer"
                                              placeholder={_t("email of employer")}
                                              onChange={onChangeTenant}
                                              value={
                                                tenantProfile?.email_previous_employer ||
                                                ""
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 mx-auto">
                                        <div
                                          className={
                                            tenantProfile?.rented_locally_before ==
                                            "yes"
                                              ? ""
                                              : "disable-add"
                                          }
                                        >
                                          <div className="info-wapper">
                                            <label>
                                              {_t("Email Of Previous Landlord")}
                                            </label>
                                            <input
                                              type="text"
                                              className="custom-input"
                                              name="email_previous_landlord"
                                              placeholder={_t("email of landlord")}
                                              value={
                                                tenantProfile?.email_previous_landlord ||
                                                ""
                                              }
                                              onChange={onChangeTenant}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="">
                                    <button
                                      className="btn-fill2 "
                                      type="submit"
                                    >
                                      {_t("Submit")}
                                    </button>
                                    <button
                                      className="btn-fill-border"
                                      onClick={() => {
                                        setFormnum("profile-info");
                                      }}
                                      style={{ marginRight: "15px" }}
                                    >
                                      {_t("Back")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                            // </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }

  if (localStorage.getItem("usertype") === "landlord") {
    if (loading) return <Loader />;
    return (
      <>
        {/* <form onSubmit={onSubmitLandlord}> */}
        <form>
          <div className="main-wapper height-auto bg-main-white">
            <div className="container">
              <div className="row">
                <div className="flex-wapper">
                  <div className="left-upload-wapper-2">
                    <div className="d-flex align-items-center ">
                      <div
                        className="nav flex-column nav-pills me-3 list-info-wapper"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          onClick={() => {
                            changeTab("profile-info");
                          }}
                          className={getActiveclassName("profile-info")}
                        >
                          {_t("Profile Information")}
                          <div className="circle-active ">
                            <img src={infoicon1} />{" "}
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            changeTab("property-info");
                          }}
                          className={getActiveclassName("property-info")}
                        >
                          {_t("Property Information")}
                          <div className="circle-active">
                            <img src={infoicon2} />{" "}
                          </div>
                        </button>
                      </div>
                      <div className="right-content-width">
                        <div className="tab-content" id="v-pills-tabContent">
                          {formNum === "profile-info" && (
                            <div className="right-content-info">
                              {/* <h2>We need to know a little about you before<br /> setting up your account.</h2>
                              <p>Please enter your details</p> */}
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <div className="info-wapper">
                                    <label>{_t("Create Account as")}:</label>
                                    <div className="select-radio">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="landlordtype"
                                          value="agent"
                                          onChange={onChangeLandlord}
                                        />
                                        <label className="form-check-label">
                                          {_t("Agent")}
                                        </label>
                                      </div>
                                      &nbsp; &nbsp;
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="landlordtype"
                                          value="landlord"
                                          onChange={onChangeLandlord}
                                        />
                                        <label className="form-check-label">
                                          {_t("LandLord")}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {LandlordProfile?.landlordtype ===
                                  "landlord" && (
                                  <>
                                    <div className="col-md-6 col-12">
                                      <div className="info-wapper">
                                        <label>
                                          {_t("Are you a Company or an Individual")}
                                        </label>
                                        <div className="select-radio">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="isLandlordInComp"
                                              value="company"
                                              onChange={onChangeLandlord}
                                            />
                                            <label className="form-check-label">
                                              {_t("company")}
                                            </label>
                                          </div>
                                          &nbsp; &nbsp;
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="isLandlordInComp"
                                              value="individual"
                                              onChange={onChangeLandlord}
                                              c
                                            />
                                            <label className="form-check-label">
                                              {_t("Individual")}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div className="col-md-6 col-12">
                                  <div className="info-wapper">
                                    <label>{_t("Company Name")}</label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      name="company_name"
                                      placeholder={_t("Enter Company Name")}
                                      onChange={onChangeLandlord}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 col-12">
                                  <div className="info-wapper">
                                    {LandlordProfile?.landlordtype ===
                                    "agent" ? (
                                      <>
                                        <label>{_t("DNI/NIE/NIF")}</label>
                                      </>
                                    ) : (
                                      <>
                                        {LandlordProfile?.isLandlordInComp ===
                                        "company" ? (
                                          <>
                                            <label>{_t("NIF")}</label>
                                          </>
                                        ) : (
                                          <>
                                            <label>{_t("DNI/NIE")}</label>
                                          </>
                                        )}
                                      </>
                                    )}

                                    <input
                                      type="text"
                                      className="custom-input"
                                      name="cif_dni_nie"
                                      placeholder={_t("DNI/NIE")}
                                      onChange={onChangeLandlord}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 col-12">
                                  <div className="info-wapper">
                                    <label>{_t("First Name")}</label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      name="fname_companyName"
                                      placeholder={_t("Enter your name")}
                                      onChange={onChangeLandlord}
                                      value={
                                        LandlordProfile?.fname_companyName || ""
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 col-12">
                                  <div className="info-wapper">
                                    <label>{_t("Last Name")}</label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      name="lname"
                                      placeholder={_t("Enter your last name")}
                                      onChange={onChangeLandlord}
                                      value={LandlordProfile?.lname || ""}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-md-6 col-12">
                                  <div className="info-wapper">
                                    <label>Phone Number</label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      name="phone"
                                      placeholder="Enter Phone number"
                                      onChange={onChangeLandlord}
                                    />
                                  </div>
                                </div> */}
                                <div className="col-sm-6 col-12">
                                    <div className="info-wapper">
                                      <label>{_t("Phone Number")}</label>

                                      <PhoneInput
                                        className="custom-input"
                                        international
                                        // defaultCountry="RU"
                                        value={phoneNumber}
                                        // onChange={setPhoneNumber}
                                        onChange={changeLandlordPhoneNumber}
                                      />
                                    </div>
                                  </div>
                                <div className="col-md-6 col-12">
                                  <div className="info-wapper">
                                    <label>{_t("Company Email")}</label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      name="email"
                                      placeholder={_t("Enter Email")}
                                      onChange={onChangeLandlord}
                                      value={LandlordProfile?.email || ""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div>
                                  <button
                                    className="btn-fill2"
                                    onClick={() => {
                                      setFormnum("property-info");
                                    }}
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}

                          {formNum === "property-info" && (
                            <div className="right-content-info">
                              <div className="row">
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-md-6 col-12">
                                      <div className="info-wapper">
                                        <label>{_t("Number of Properties")}</label>
                                        <select
                                          className="form-select"
                                          aria-label={_t("Default select example")}
                                          defaultValue={0}
                                          onChange={handleProperties}
                                        >
                                          <option value="0">
                                            {_t("Please select number of properties")}
                                          </option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {myProperties.length ? (
                                  <div className="row">{addProperty()}</div>
                                ) : null}
                              </div>
                              <div className="row">
                                <div>
                                  <button
                                    className="btn-fill2"
                                    onClick={onSubmitLandlord}
                                    type="submit"
                                  >
                                    {_t("Submit")}
                                  </button>
                                  <button
                                    className="btn-fill-border"
                                    onClick={() => {
                                      setFormnum("profile-info");
                                    }}
                                    style={{ marginRight: "15px" }}
                                  >
                                    {_t("Back")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
};

export default Profile;
