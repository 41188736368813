import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../../hooks/useTranslator";
import { useContext, useEffect, useState } from "react";
import TenantContext from "../../context/tenant/TenantContext";
import constants from "../../Utils/constants";
import AuthContext from "../../context/auth/AuthContext";
import { toast } from "react-toastify";
import moment from "moment";
import LandLordContext from "../../context/landlord/LandLordContext";

const TenantRentalDetails = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const landLordContext = useContext(LandLordContext);
  const tenantContext = useContext(TenantContext);
  const { user, setLoading } = authContext;
  const { fetchAssignedDetails } = landLordContext;
  const { getRentalInfo, ledgerData } = tenantContext;

  const [rentalDetails, setRentalDetails] = useState({});
  const [rentDates, setRentDates] = useState([]);

  useEffect(() => {
    if (user?.rental_data?.type == constants.RENTAL_INFO) {
      getRentalInfo()
        .then((res) => {
          setRentalDetails(res.success_res);
          console.log(res, "check rental data");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      fetchAssignedDetails(user?.rental_data?.id)
        .then((res) => {
          console.log("DATA CAME INSIDE");
          setRentalDetails(res.result);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(_t("Error while fetching details"));
        });
    }
    ledgerData()
      .then((res) => {
        const data = res.success_res;
        checkPaymentCard(data);
        // setPaymentHistory(data);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(_t("Error while fetching details"));
      });
  }, [user]);

  const checkPaymentCard = (data) => {
    var temp = [];
    var count = 0;

    for (let i = 0; i < data.length; i++) {
      const val = data[i];
      console.log("val", val, "val");
      if (count == 4) {
        break; // This will exit the loop entirely
      }
      if (val.status == constants.COMPLETED && val.type == constants.RENT) {
        temp.push(val);
        count++;
      }
    }
    temp = temp.reverse();
    setRentDates(temp);
  };

  const navigateRevisitPayment = () => {
    navigate("/tenant/rentalStep?revisit=true");
  };
  console.log("rentalDetailsrentalDetails", rentalDetails);
  return (
    <>
      <div className="pending-steps-wrapper">
        <div className="d-flex justify-content-between">
          <h3 className="skore-details-heading">{_t("Rent Details")}</h3>
          <Link className="view-all-rent" to={"/tenant/review/rental"}>
            {_t("View All Details")}
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-6 padd-set-for-col">
            <div className="cust-card-wrapper">
              <h4 className="card-heading">{_t("Address")}:</h4>
              <div className="card-content">
                <img
                  className="card-content-image"
                  src="/images/home.svg"
                  alt="home-icon"
                />
                <h5 className="card-content-heading">
                  {user?.rental_data?.type == constants.RENTAL_INFO
                    ? `
                    ${rentalDetails?.street || ""} ${
                        rentalDetails?.street_number || ""
                      }, ${rentalDetails?.floor_number || ""} - ${
                        rentalDetails?.flat_number || ""
                      }
                    ${rentalDetails?.province || ""}, ${
                        rentalDetails?.postcode || ""
                      }
                  `
                    : `${rentalDetails?.property_id?.street || ""} ${
                        rentalDetails?.property_id?.street_number || ""
                      }, ${rentalDetails?.property_id?.floor_number || ""} - ${
                        rentalDetails?.property_id?.flat_number || ""
                      }
                    ${rentalDetails?.property_id?.province || ""}, ${
                        rentalDetails?.property_id?.postcode || ""
                      }`}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-6 padd-set-for-col">
            <div className="Milestones-dates">
              <h3 className="Milestones-head">{_t("Milestones")}:</h3>
              <div className="overflow-for-mob">
                <div class="stepper-wrapper">
                  {rentDates.map((val) => (
                    <div class="stepper-item completed">
                      <div class="step-counter">
                        <img src="/images/check.png" alt="" />
                      </div>
                      <div class="step-name">10 Points</div>
                    </div>
                  ))}
                  <div class="stepper-item">
                    <div class="step-counter"></div>
                    {/* <div class="step-name">50 Points</div> */}
                  </div>
                </div>

                <div className="cards-wrapper">
                  {rentDates.map((val) => (
                    <div className="p-2">
                      <div className="date-card">
                        <p className="date-card-header">
                          {moment(val.processing_date).format("MMM")}
                        </p>
                        <div className="date-card-footer">
                          <p className="card-date">
                            {moment(val.processing_date).date()}
                          </p>
                          <p className="card-year">
                            {moment(val.processing_date).year()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="p-2">
                    <div className="date-card incomplete-date">
                      <p className="date-card-header">
                        {moment().clone().add(1, "months").format("MMM")}
                      </p>
                      <div className="date-card-footer">
                        <p className="card-date">
                          {moment(
                            rentDates[0]?.processing_date || new Date()
                          ).date()}
                        </p>
                        <p className="card-year">
                          {moment().clone().add(1, "months").year()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex tile-design pad-mar-set border-top border-bottom-0 pb-0 mb-0">
          <div class="tile-content-wrapper">
            <div class="tile-content">
              <h4 class="tile-heading">{_t("Pay Your Monthly Rent")}</h4>
              <p class="tile-desc">
                {_t(
                  "Pay your next rent payment through Skor and keep earning points while improving your rental history."
                )}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center gap-4 tile-btns-wrapper">
            <button class="incomplete-btn" onClick={navigateRevisitPayment}>
              {_t("Pay Now")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default TenantRentalDetails;
