import { haveValue } from "../../helper/common";
import useTranslator from "../../hooks/useTranslator";

const RentalDetails = (props) => {
  const { _t } = useTranslator();
  const { formData } = props;
  const landlordName = `${
    haveValue(formData?.landlord_id?.fname_companyName)
      ? formData?.landlord_id?.fname_companyName
      : ""
  } ${
    haveValue(formData?.landlord_id?.lname) ? formData?.landlord_id?.lname : ""
  }`;
  const propAddr = `${formData?.property_id?.street} - ${formData?.property_id?.street_number}, ${formData?.property_id?.floor_number}-${formData?.property_id?.flat_number}`;
  // console.log(formData,"formDataformData");
  return (
    <>
      <div className="rental-details">
        <div className="rentail-headsec">
          <div className="heading-check">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <circle cx="16" cy="16" r="16" fill="#A3EB64" />
              <path
                d="M11.8654 20.314L7.93019 16.4506C7.71814 16.2425 7.43055 16.1255 7.13068 16.1255C6.8308 16.1255 6.54321 16.2425 6.33117 16.4506C6.11912 16.6588 6 16.9412 6 17.2356C6 17.3813 6.02925 17.5257 6.08607 17.6604C6.14289 17.795 6.22617 17.9174 6.33117 18.0205L11.0715 22.6743C11.5138 23.1086 12.2283 23.1086 12.6705 22.6743L24.6688 10.895C24.8809 10.6868 25 10.4044 25 10.11C25 9.81564 24.8809 9.5333 24.6688 9.32512C24.4568 9.11695 24.1692 9 23.8693 9C23.5695 9 23.2819 9.11695 23.0698 9.32512L11.8654 20.314Z"
                fill="#223635"
              />
            </svg>

            <h3 className="rental_detail_heading">{_t("Rental Details")}</h3>
          </div>
          <div className="d-flex flex-wrap">
            {/* <div> */}
              <h4 className="rental_details">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 47 32"
                  fill="none"
                >
                  <path
                    d="M21.5417 12V16H17.625V20H13.7083V16H11.3583C10.575 18.4 8.42083 20 5.875 20C2.54583 20 0 17.4 0 14C0 10.6 2.54583 8 5.875 8C8.42083 8 10.575 9.6 11.3583 12H21.5417ZM5.875 12C4.7 12 3.91667 12.8 3.91667 14C3.91667 15.2 4.7 16 5.875 16C7.05 16 7.83333 15.2 7.83333 14C7.83333 12.8 7.05 12 5.875 12ZM31.3333 20C36.6208 20 47 22.6 47 28V32H15.6667V28C15.6667 22.6 26.0458 20 31.3333 20ZM31.3333 16C27.025 16 23.5 12.4 23.5 8C23.5 3.6 27.025 0 31.3333 0C35.6417 0 39.1667 3.6 39.1667 8C39.1667 12.4 35.6417 16 31.3333 16Z"
                    fill="#253837"
                  />
                </svg>
                {landlordName}
              </h4>
              <h4 className="rental_details">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 34 33"
                  fill="none"
                >
                  <path
                    d="M18.2015 0.483103L33.4987 15.3333C34.5695 16.3728 33.8114 18.1498 32.297 18.1498H30.5973V28.0499C30.5973 29.3628 30.0601 30.6218 29.1038 31.5502C28.1476 32.4785 26.8506 33 25.4982 33H23.7986V21.4499C23.7986 20.1873 23.3017 18.9724 22.4094 18.0537C21.5172 17.1351 20.297 16.5821 18.9986 16.5081L18.6995 16.4998H15.3001C13.9478 16.4998 12.6508 17.0213 11.6946 17.9497C10.7383 18.878 10.2011 20.137 10.2011 21.4499V33H8.5014C7.14904 33 5.85208 32.4785 4.89582 31.5502C3.93956 30.6218 3.40234 29.3628 3.40234 28.0499V18.1498H1.70266C0.189936 18.1498 -0.569823 16.3728 0.500978 15.3333L15.7981 0.483103C16.1169 0.173772 16.5491 0 16.9998 0C17.4505 0 17.8828 0.173772 18.2015 0.483103ZM18.6995 19.7999C19.1503 19.7999 19.5826 19.9737 19.9014 20.2831C20.2201 20.5926 20.3992 21.0123 20.3992 21.4499V33H13.6005V21.4499C13.6005 21.0457 13.7533 20.6557 14.03 20.3537C14.3066 20.0516 14.6878 19.8587 15.1013 19.8114L15.3001 19.7999H18.6995Z"
                    fill="#253837"
                  />
                </svg>
                {propAddr}
              </h4>
            {/* </div>
            <div> */}
              <h4 className="rental_details">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 9C2 7.114 2 6.172 2.586 5.586C3.172 5 4.114 5 6 5H18C19.886 5 20.828 5 21.414 5.586C22 6.172 22 7.114 22 9C22 9.471 22 9.707 21.854 9.854C21.707 10 21.47 10 21 10H3C2.529 10 2.293 10 2.146 9.854C2 9.707 2 9.47 2 9ZM2 18C2 19.886 2 20.828 2.586 21.414C3.172 22 4.114 22 6 22H18C19.886 22 20.828 22 21.414 21.414C22 20.828 22 19.886 22 18V13C22 12.529 22 12.293 21.854 12.146C21.707 12 21.47 12 21 12H3C2.529 12 2.293 12 2.146 12.146C2 12.293 2 12.53 2 13V18Z"
                    fill="#253837"
                  />
                  <path
                    d="M7 3V6M17 3V6"
                    stroke="#253837"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
                {new Date(formData?.payment_start).toLocaleDateString("en-GB")}{" "}
                {haveValue(formData?.lease_end) &&
                  `- ${new Date(formData?.lease_end).toLocaleDateString(
                    "en-GB"
                  )}`}
              </h4>
              <h4 className="rental_details">
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7580_1120)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.5 0.109375C11.1321 0.109673 10.7716 0.212042 10.4584 0.405089L0.749143 6.76295C0.0591429 7.21802 -0.151143 8.00166 0.115 8.6703C0.371286 9.3143 1.01857 9.74966 1.79071 9.74966H21.2093C21.9814 9.74966 22.6287 9.3143 22.885 8.6703C23.1511 8.00166 22.9409 7.21802 22.2525 6.76459L22.2509 6.76295L12.5416 0.405089C12.2284 0.212042 11.8679 0.109673 11.5 0.109375ZM0 19.5132C0 18.5274 0.939714 18.0527 1.64286 18.0527H1.73321C1.72998 18.0232 1.72834 17.9936 1.72829 17.9639V12.6887C1.72829 12.4709 1.81483 12.2619 1.96888 12.1079C2.12292 11.9538 2.33186 11.8673 2.54971 11.8673H5.28343C5.50129 11.8673 5.71022 11.9538 5.86427 12.1079C6.01831 12.2619 6.10486 12.4709 6.10486 12.6887V17.9639C6.10479 17.9936 6.10315 18.0232 6.09993 18.0527H9.31664C9.31342 18.0232 9.31178 17.9936 9.31172 17.9639V12.6887C9.31172 12.4709 9.39826 12.2619 9.55231 12.1079C9.70635 11.9538 9.91529 11.8673 10.1331 11.8673H12.8669C12.9747 11.8673 13.0815 11.8885 13.1812 11.9298C13.2809 11.9711 13.3714 12.0316 13.4477 12.1079C13.524 12.1842 13.5845 12.2747 13.6258 12.3744C13.667 12.474 13.6883 12.5809 13.6883 12.6887V17.9639C13.6882 17.9936 13.6866 18.0232 13.6834 18.0527H16.9001C16.8969 18.0232 16.8952 17.9936 16.8951 17.9639V12.6887C16.8951 12.4709 16.9817 12.2619 17.1357 12.1079C17.2898 11.9538 17.4987 11.8673 17.7166 11.8673H20.4503C20.6681 11.8673 20.8771 11.9538 21.0311 12.1079C21.1852 12.2619 21.2717 12.4709 21.2717 12.6887V17.9639C21.2717 17.9936 21.27 18.0232 21.2668 18.0527H21.3571C22.0603 18.0527 23 18.5274 23 19.5148V21.432C23 22.4177 22.0603 22.8925 21.3571 22.8925H1.64286C0.939714 22.8925 0 22.4177 0 21.4304V19.5132Z"
                      fill="#223635"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7580_1120">
                      <rect width="23" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="monthly-deposit">
                  <div>
                    <h6 className="monthly-paymenttext">
                      {_t("Monthly payment")}
                    </h6>
                    <p>€{formData?.rent_amount}</p>
                  </div>
                  <div>
                    <h6 className="monthly-paymenttext">
                      {_t("Deposit amount")}
                    </h6>
                    <p>€{formData?.deposit_amount}</p>
                  </div>
                </div>
              </h4>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalDetails;
