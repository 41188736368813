import { useContext, useEffect, useState } from "react";
import useTranslator from "../../../hooks/useTranslator";
import axios from "axios";
import AuthContext from "../../../context/auth/AuthContext";
import { init } from "onfido-sdk-ui";
import { Container, Modal } from "react-bootstrap";
import TenantContext from "../../../context/tenant/TenantContext";
import { useNavigate } from "react-router-dom";
import { haveValue } from "../../../helper/common";
import constants from "../../../Utils/constants";

const TenantVerifyId = () => {
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { user, loadUser, userType } = authContext;
  const { onfidoKyc, updateKyc, updateTenant } = tenantContext;
  const { _t } = useTranslator();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const openBackModal = () => {
    setOpenModal(true);
  };

  const initiateOnfido = () => {
    setButtonDisable(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${user?._id}`)
      .then((res) => {
        setButtonDisable(false);
        initiateKyc(res.data.sdk_data.token, res.data.workflow_data.id);
      })
      .catch((err) => {
        setButtonDisable(false);
      });
  };

  const initiateKyc = (token, workFlowRunId) => {
    //console.log("On Fido Token = ", token);
    setButtonDisable(true);
    let onfido = {};

    onfido = init({
      token: token,
      useModal: true,
      isModalOpen: true,
      containerId: "onfido-mount",
      workflowRunId: `${workFlowRunId}`,
      onComplete: function (data) {
        console.log("everything is complete");

        setButtonDisable(false);
        saveOnfidoKycResponse(data);
        loadUser(userType);
        setCurrentStep(1);
      },
      onError: function (err) {
        setButtonDisable(false);
      },
      onUserExit: function (userExitCode) {
        console.log("user exitted", userExitCode);
        setButtonDisable(false);
      },
      onModalRequestClose: function () {
        onfido.setOptions({ isModalOpen: false });
        setButtonDisable(false);
        console.log("modal closed of onfido");
      },
      steps: ["welcome", "document", "face", "complete"],
    });
  };

  const saveOnfidoKycResponse = (kycData) => {
    onfidoKyc(user?._id, kycData);
  };

  const navigateBackHome = () => {
    navigate("/tenant");
  };

  const handleGobackReason = (reason) => {
    const data = {
      user_id: user?._id,
      exit_reason: reason,
    };
    updateKyc(data)
      .then((res) => {
        navigate("/tenant");
        console.log("Status updated");
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("reason", reason);
  };

  const checkNextButton = () => {
    if (
      (!haveValue(user?.have_gurantor) ||
        !user?.have_gurantor ||
        user?.have_gurantor == "false") &&
      (!haveValue(user?.kyc_success) ||
        !user?.kyc_success ||
        user?.kyc_success == "false")
    ) {
      return (
        <button
          className="btn btnorange step-btn"
          onClick={(e) => navigate("/tenant/connectBank")}
        >
          {_t("Continue To Connect Bank")}
        </button>
      );
    } else if (!haveValue(user?.docs_uploaded) || !user?.docs_uploaded) {

      return (
        <button onClick={(e) => navigate("/tenant/document")}>
          {_t("Continue to Provide Document")}
        </button>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div id="onfido-mount"></div>
      {currentStep == 0 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a href={undefined} onClick={openBackModal}>
                    <img src="/images/back-btn-white.svg" alt="" />
                    <span>{_t("Go Back To Home")} </span>
                  </a>
                </div>
              </Container>
              <h3 class="step-form-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <circle cx="27" cy="27" r="27" fill="#253837" />
                  <path
                    d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                    fill="#A3EB64"
                  />
                </svg>
                <span>{_t("Verify ID")}</span>
              </h3>
            </div>
            <div className="steps-wrapper">
              <h3 className="step-name">
                {_t("Use original document for verification")}
              </h3>
              <p className="step-sub-head">
                {_t("We recommend to use Spanish ID, if available")}
              </p>
              <div className="icon-text-box">
                <img src="/images/doc-icon.svg" alt="" />
                <div className="text-box">
                  <h5 className="box-heading">
                    {_t("Get your original document")}
                  </h5>
                  <p className="box-desc">
                    {_t("You need to have the physical ID with you")}
                  </p>
                </div>
              </div>
              <div className="icon-text-box">
                <img src="/images/doc-icon.svg" alt="" />
                <div className="text-box">
                  <h5 className="box-heading">
                    {_t("Select the country and type of document")}
                  </h5>
                  <p className="box-desc">
                    {_t(
                      "Make sure you pick the appropriate ones according to your document"
                    )}
                  </p>
                </div>
              </div>
              <div className="icon-text-box">
                <img src="/images/doc-icon.svg" alt="" />
                <div className="text-box">
                  <h5 className="box-heading">
                    {_t("Take a picture and verify it")}
                  </h5>
                  <p className="box-desc">
                    {_t(
                      "Get into your phone to take a picture of your original document and get it verified."
                    )}
                  </p>
                </div>
              </div>
              <p className="note-text">
                {_t(
                  "Note: photocopies or other existing pictures are not allowed."
                )}
              </p>
              <button
                className="btn btnorange step-btn"
                onClick={initiateOnfido}
                disabled={buttonDisable}
              >
                {_t("Verify ID")}
              </button>
            </div>
          </div>
          <Modal
            className="modal-uploadpdf"
            show={openModal}
            onHide={(e) => setOpenModal(false)}
          >
            <Modal.Header closeButton>
              <h5 className="modal-main-heading">
                {_t("Why Do You Want to Leave")}?
              </h5>
            </Modal.Header>
            <Modal.Body>
              <p className="modal-sub-heading">{_t("Please Choose One")}</p>
              <button
                className="pdf-btn"
                onClick={(e) =>
                  handleGobackReason(
                    "I don't have the original document with me"
                  )
                }
              >
                {_t("I don't have the original document with me")}
              </button>
              <button
                className="pdf-btn"
                onClick={(e) =>
                  handleGobackReason(
                    "I am unable to capture the image of the document"
                  )
                }
              >
                {_t("I am unable to capture the image of the document")}
              </button>
              <button
                className="pdf-btn"
                onClick={(e) => handleGobackReason("I am getting an error")}
              >
                {_t("I am getting an error")}
              </button>
            </Modal.Body>
          </Modal>
        </>
      )}
      {currentStep == 1 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="congratulation-screen steps-wrapper">
              <div className="step-form-header">
                <h3 class="step-form-name">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                  >
                    <circle cx="27" cy="27" r="27" fill="#253837" />
                    <path
                      d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                      fill="#A3EB64"
                    />
                  </svg>
                  <span>{_t("Verify ID")}</span>
                </h3>
              </div>
              <img
                className="congrats-image"
                src="/images/confetti.svg"
                alt=""
              />
              <h3 className="congrats-text">{_t("Congratulations")}</h3>
              <p className="congrats-desc">
                {_t(
                  "Your identification verification is in progress. Once verified you will be rewarded with 30 points"
                )}
              </p>
              {checkNextButton()}
              <button className="btn btnoutline" onClick={navigateBackHome}>
                {_t("Go Back home")}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TenantVerifyId;
