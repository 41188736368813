import React, { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../context/auth/AuthContext";
import { Link, useNavigate } from "react-router-dom";

// import '../assets/css/navbar.css'
import crossNew from "../assets/img/cross-new.svg";
import bellicon from "../assets/img/bell.png";
import eyeicon from "../assets/img/eye.png";
import logouticon from "../assets/img/logout.png";
import useTranslator from "../hooks/useTranslator";
import { haveValue } from "../helper/common";
import i18next from "i18next";
import { MdInfoOutline } from "react-icons/md";
import { Modal } from "react-bootstrap";



//NEED TO ADD FEATURE TO MAKE THE COUNT AS 0



const Navbar = () => {
  const { _t } = useTranslator();

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng").toLowerCase() || "en-us"
  );

  const [showModal, setShowModal] = useState(false)

  const [ureadCount, setUnreadCount] = useState(0)

  const authContext = useContext(AuthContext);
  const { isLoggedIn, user, notifications, userType, logout, changeUserLng } =
    authContext;
  const navigate = useNavigate();

  const takeMeToProfile = () => {
    navigate(`/${userType}-profile`);
  };

  useEffect(() => {
    var count = 0;
    if (notifications && notifications.length > 0) {
      notifications.forEach(val => {
        if (val.marked_read == false) {
          count++;
        } else {
          return
        }
      });
      setUnreadCount(count)
    }
  }, [notifications])

  useEffect(() => {

    if (haveValue(user?.lang)) {
      const e = { target: { value: user?.lang } };

      handleLanguageChange(e);
    }
  }, [user]);

  const handleLanguageChange = async (e) => {
    var langVal = e.target.value;
    await i18next.changeLanguage(langVal);
    await changeUserLng();
    setCurrentLang(langVal);
  };


  return (
    <>
      <div id="header" className="head-cus">
        <div className="header-left float-left custom-header">
          <div className="firstaside">
            <h5>
              {_t("Hi")}, {user?.fname || user?.fname_companyName} {user?.lname}
            </h5>
            {userType == "tenant" &&
              user?.onfido_kyc_success != true &&
              !haveValue(user?.gurantor_id?.paymentScores) &&
              !haveValue(user?.paymentScores) && (
                <h6 className="mob-none">
                  {
                    (user?.is_university && user?.ie_verified) ?
                      _t("You have just Skored yourself as an IE student, share your Skor with landlords/agents. To improve your Skor, please connect your bank account and/or add a Guarantor.")
                      :
                      _t("To receive and share your Skor with your Landlord/Agent, please complete digital verification or link your bank account.")
                  }
                </h6>
              )}
            {userType == "tenant" &&
              user?.onfido_kyc_success != true &&
              !haveValue(user?.gurantor_id?.paymentScores) &&
              !haveValue(user?.paymentScores) && (
                <div className="tooltipbar">
                  <MdInfoOutline />
                  <h6 className=""> {
                    (user?.is_university && user?.ie_verified) ?
                      _t("You have just Skored yourself as an IE student, share your Skor with landlords/agents. To improve your Skor, please connect your bank account and/or add a Guarantor.")
                      :
                      _t("To receive and share your Skor with your Landlord/Agent, please complete digital verification or link your bank account.")
                  }</h6>
                </div>
              )}
          </div>
          <div className="second-aisde">
            <div class="menu-togglebar">
              <label class="customradiobtn">
                <input
                  type="radio"
                  onClick={handleLanguageChange}
                  checked={
                    currentLang == "en-us" || currentLang == "en-US"
                      ? "checked"
                      : ""
                  }
                  name="radio"
                  value={"en-us"}
                />
                <span class="checkmark1">EN</span>
              </label>
              <label class="customradiobtn">
                <input
                  type="radio"
                  onClick={handleLanguageChange}
                  checked={currentLang == "es" ? "checked" : ""}
                  name="radio"
                  value={"es"}
                />
                <span class="checkmark1">ES</span>
              </label>
            </div>
            <div className="slideryt">
              <div className="re-div">
                <div className="dropdown admin-btn">
                  <Link data-bs-toggle="dropdown">
                    <div className="admin-name">
                      {userType == "landlord" ? (
                        <>
                          {user?.fname_companyName &&
                            user?.fname_companyName.charAt(0).toUpperCase()}
                          {user?.lname && user?.lname.charAt(0).toUpperCase()}
                        </>
                      ) : (
                        <>
                          {user?.fname && user?.fname.charAt(0).toUpperCase()}
                          {user?.lname && user?.lname.charAt(0).toUpperCase()}
                        </>
                      )}
                    </div>

                    <ul className="dropdown-menu">
                      <div
                        className="hover-dropdown"
                        style={{ display: "block" }}
                      >
                        <Link onClick={takeMeToProfile}>
                          <img src={eyeicon} alt="" /> {_t("View Profile")}
                        </Link>
                        <Link onClick={e => setShowModal(true)} className="pb-0">
                          <img src={logouticon} alt="" /> {_t("Log Out")}
                        </Link>
                      </div>
                    </ul>
                  </Link>
                </div>
                {/* <div className="hover-dropdown">
                              <a href="#" className="mt-1"><img src="./img/eye.png" alt=""/> View Profile</a>
                              <a href="#"><img src="./img/logout.png" alt=""/> Log Out</a>
                           </div> */}
              </div>
              <div>
                <Link to="/notification">
                  <span className="badge text-white">
                    {ureadCount}
                  </span>
                  <img src={bellicon} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} centered className="newmodalui asguarantor">
        <Modal.Header></Modal.Header>
        <Modal.Body>

          <div className="forminput row100">
            {_t("Are you sure you want to log out")}?
          </div>
          <div className="btngroup row100 gridbar">
            <button className="btn btnoutline cancel-btn" type="button" onClick={e => setShowModal(false)}>
              {_t("Cancel")}
            </button>
            <button className="btn btnorange" type="button" onClick={logout} >
              {_t("Logout")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
