import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import tickNew from "../assets/img/tick-new.svg";
import exclamation from "../assets/img/exclamation.svg";
import crossNew from "../assets/img/cross-new.svg";
import useTranslator from "../hooks/useTranslator";
import countryList from "country-list";
import countries from "i18n-iso-countries";
import { convertCurrencyFormat, haveValue } from "../helper/common";
import { toast } from "react-toastify";
import moment from "moment";
import constants from "../Utils/constants";
import { IoClose } from "react-icons/io5";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image-more";
import html2canvas from "html2canvas";
import AuthContext from "../context/auth/AuthContext";
import TenantContext from "../context/tenant/TenantContext";
import QRCode from "react-qr-code";
import PdfRender from "../components/PdfRender";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const ProfilePreview = () => {
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const { setLoading, getUserDetails } = authContext;
  const navigate = useNavigate();

  const { UserId } = useParams();

  const [user, setUser] = useState(null);
  const [ident_all, setIdent_all] = useState(false);
  const [tenantDocsData, setTenantDocsData] = useState(null);

  //extracting url parameters
  const queryParameters = new URLSearchParams(window.location.search);

  const user_type = queryParameters.get("user_type");

  const countries1 = countryList.getData();
  const getAlpha3Code = (countryName) => {
    const country = countries1.find(
      (c) => c.name.toLowerCase() === countryName
    );
    return country ? countries.alpha2ToAlpha3(country.code) : "Not found";
  };

  const tenantContext = useContext(TenantContext);
  const {
    myRentedProperties,
    myPreviouslyRentedProperties,
    kycuserinfo,
    fetchTenantDocs,
  } = tenantContext;

  const [progressColor, setProgressColor] = useState("#F27833");

  const [scoreLoading, setScoreLoading] = useState(false);
  const [userKycInfo, setUserKycInfo] = useState({});
  const [recordedSalary, setRecordedSalary] = useState({
    salary: 0,
    matched: 0,
  });

  const [isEmployeementDataSet, setIsEmployeementDataSet] = useState(false);
  const [haveGuarantor, setHaveGuarantor] = useState(false);

  useEffect(() => {
    async function fetchDetails() {
      const userDetails = await getUserDetails("tenant", UserId);
      setUser(userDetails);
    }

    fetchDetails();
  }, []);

  useEffect(() => {
    if (user) {
      const user_type = "tenant";
      const userId = UserId;
      if (!user?.analysisCompleted && user?.algoanAnalysis) {
        setScoreLoading(true);
      }

      myRentedProperties(user?._id);
      myPreviouslyRentedProperties(user?._id);

      if (!user?.onfido_kyc_success) {
        getKycReport(user?._id);
      }

      if (user?.employment_status == constants.employed) {
        if (user?.name_of_company && user?.position && user?.type_of_employee) {
          setIsEmployeementDataSet(true);
        }
      }

      if (
        user?.have_gurantor == "no" ||
        user?.have_gurantor == null ||
        user?.haveGuarantor == false
      ) {
        setHaveGuarantor(false);
      } else {
        setHaveGuarantor(true);
      }
      fetchFileData();
    }
  }, [user]);

  useEffect(() => {
    checkSalary();
    getkycDetails();
  }, [kycuserinfo]);

  useEffect(() => {
    checkAllMatches();
  }, [userKycInfo, user]);

  const checkSalary = () => {
    if (haveValue(user?.gurantor_id)) {
      if (user?.gurantor_id?.algoanIncome) {
        let monthlyIncome = parseInt(
          user?.gurantor_id?.algoanIncome?.monthlyAmount
        );
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user?.gurantor_id?.current_annual_income)
            ? user?.gurantor_id?.current_annual_income
            : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        setRecordedSalary({
          ...recordedSalary,
          matched: salaryMatch,
          salary: recorded_annual_salary,
        });
      }
    } else {
      if (user?.algoanIncome) {
        let monthlyIncome = parseInt(user?.algoanIncome?.monthlyAmount);
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user.current_annual_income) ? user.current_annual_income : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        setRecordedSalary({
          ...recordedSalary,
          matched: salaryMatch,
          salary: recorded_annual_salary,
        });
      }
    }
  };

  useEffect(() => {
    getkycDetails();
  }, [user]);

  const getkycDetails = () => {
    let userid = UserId;
    if (user?.onfido_kyc_success) {
      //// console.log("kyc has been done");
      loadKycReport(userid);
    } else {
      //// console.log("kyc has  been done but result has not been fetched");
      getKycReport(userid);
    }
  };

  const loadKycReport = async (tenantid) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/getkycreport/${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        //// console.log(res);
        //toast.success("KYC Report Fetched");
        setUserKycInfo(res.data.kycuser);
      })
      .catch((err) => {
        //console.log(err);
        //toast.error("Kyc Report Not Fetched")
      });
  };

  const getKycReport = async (tenantid) => {
    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/getkycstatus/${tenantid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          ////  console.log(res);
          if (res.data.status === "success") {
            //toast.success("KYC Report Fetched")
            setUserKycInfo(res.data.kycuser);
            clearInterval(myInterval);
          } else {
            clearInterval(myInterval);
          }
        })
        .catch((err) => {
          //console.log(err);
          //toast.error("Kyc Report Not Fetched")
          clearInterval(myInterval);
        });
    }, 5000);
  };

  const checkCompanyname = () => {
    let companyname = user?.name_of_company;
    if (user?.algoanIncome?.employers.length !== 0 && user?.algoanIncome) {
      for (let company of user?.algoanIncome?.employers) {
        if (companyname === company) {
          return <img src={tickNew} alt="" />;
        }
      }
    } else {
      return <img src={crossNew} alt="" />;
    }
  };

  const verificationNotDone = () => {
    return (
      <ul className="infodorpdown scrorebardrop">
        <li>
          <button className="iconinfo">
            <img src={crossNew} alt="" />
          </button>{" "}
        </li>
      </ul>
    );
  };

  const verificationInProgress = () => {
    return (
      <ul className="infodorpdown scrorebardrop">
        <li>
          <button className="iconinfo">
            <img src={exclamation} alt="" />
          </button>{" "}
        </li>
      </ul>
    );
  };

  const checkNationality = () => {
    let kyc_nationality =
      userKycInfo?.onfido_doc_data?.nationality.toLowerCase();
    let country_code = getAlpha3Code(user?.nationality.toLowerCase());

    if (
      kyc_nationality &&
      (user?.nationality.toLowerCase() == kyc_nationality ||
        country_code.toLowerCase() == kyc_nationality)
    ) {
      // return kyc_nationality;
      return <img src={tickNew} alt="" />;
    } else {
      if (user?.onfido_kyc_success == true) {
        return (
          <ul className="infodorpdown scrorebardrop">
            <li>
              <button className="iconinfo">
                <img src={crossNew} alt="" />
              </button>{" "}
            </li>
          </ul>
        );
      } else {
        if (
          user?.onfido_kyc_success == false &&
          user?.onfido_user_kyc?.onfido_doc &&
          user?.onfido_user_kyc?.onfido_workflow_res?.status != "declined"
        )
          return verificationInProgress();
        else return verificationNotDone();
      }
    }
  };

  const checkPassportKYC = () => {
    let kyc_type = user?.onfido_user_kyc?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.document_number;
    if (kyc_type == "passport") {
      if (document_number == user?.passport_id)
        return <img src={tickNew} alt="" />;
      else {
        if (user?.onfido_kyc_success == true) {
          return (
            <ul className="infodorpdown scrorebardrop">
              <li>
                <button className="iconinfo">
                  <img src={crossNew} alt="" />
                </button>{" "}
              </li>
            </ul>
          );
        } else {
          if (
            user?.onfido_kyc_success == false &&
            user?.onfido_user_kyc?.onfido_doc &&
            user?.onfido_user_kyc?.onfido_workflow_res?.status != "declined"
          )
            return verificationInProgress();
          else return verificationNotDone();
        }
      }
    }

    return "";
  };
  const checkDNIKyc = () => {
    let kyc_type = user?.onfido_user_kyc?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.personal_number;
    if (kyc_type != "passport") {
      if (document_number == user?.dni_nie) return <img src={tickNew} alt="" />;
      else {
        if (user?.onfido_kyc_success == true) {
          return (
            <ul className="infodorpdown scrorebardrop">
              <li>
                <button className="iconinfo">
                  <img src={crossNew} alt="" />
                </button>{" "}
              </li>
            </ul>
          );
        } else {
          if (
            user?.onfido_kyc_success == false &&
            user?.onfido_user_kyc?.onfido_doc &&
            user?.onfido_user_kyc?.onfido_workflow_res?.status != "declined"
          )
            return verificationInProgress();
          else return verificationNotDone();
        }
      }
    }

    return "";
  };

  const getEmploymentStatus = () => {
    if (user?.employment_status == constants.employed) {
      return _t("Employed");
    }
    if (user?.employment_status == constants.self_employed) {
      return _t("Self-Employed");
    }
    if (user?.employment_status == constants.student) {
      return _t("Student");
    }
    if (user?.employment_status == constants.unemployed) {
      return _t("Unemployed");
    }
  };

  const checkAllMatches = async () => {
    //console.log("CHECKALLMATCHES CALLED!")
    let fname = user?.fname.toString();
    let lname = user?.lname.toString();
    let newName = haveValue(fname) ? fname.split(" ") : "";
    let newLname = haveValue(lname) ? lname.split(" ") : "";

    let temp_fname = "";
    for (let i = 0; i < newName.length; i++) {
      newName[i] = newName[i].toLowerCase();
      temp_fname = temp_fname + newName[i];
    }

    let temp_lname = "";
    for (let i = 0; i < newLname.length; i++) {
      newLname[i] = newLname[i].toLowerCase();
      temp_lname = temp_lname + newLname[i];
    }

    let user_fullname = temp_fname + temp_lname;

    let kyc_fullname = "";
    if (
      (userKycInfo?.onfido_doc_data?.first_name,
      userKycInfo?.onfido_doc_data?.last_name)
    ) {
      let kyc_fname = userKycInfo?.onfido_doc_data?.first_name;
      let kyc_lname = userKycInfo?.onfido_doc_data?.last_name;

      let kycName = kyc_fname.split(" ");
      let kycLname = kyc_lname.split(" ");

      let temp_fname1 = "";
      for (let i = 0; i < newName.length; i++) {
        if (kycName[i]) {
          kycName[i] = kycName[i].toLowerCase();
          temp_fname1 = temp_fname1 + kycName[i];
        }
      }

      let temp_lname1 = "";
      for (let i = 0; i < newLname.length; i++) {
        if (kycLname[i]) {
          kycLname[i] = kycLname[i].toLowerCase();
          temp_lname1 = temp_lname1 + kycLname[i];
        }
      }

      kyc_fullname = temp_fname1 + temp_lname1;
    }
    // if (user_fullname !== kyc_fullname) {
    //   // if (true) {
    //   // console.log("failed in name")
    //   setIdent_all(false);
    //   return false;
    // }

    if (user?.onfido_kyc_success == false) {
      // if (true) {
      // console.log("failed in name")
      setIdent_all(false);
      return false;
    }

    let kyc_nationality =
      userKycInfo?.onfido_doc_data?.nationality.toLowerCase();
    let country_code = getAlpha3Code(user?.nationality.toLowerCase());

    if (
      kyc_nationality &&
      user?.nationality.toLowerCase() != kyc_nationality &&
      country_code.toLowerCase() != kyc_nationality
    ) {
      // console.log("failed in nationality")
      setIdent_all(false);
      return false;
    }

    let kyc_type = user?.onfido_user_kyc?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.document_number;
    let personal_number = userKycInfo?.onfido_doc_data?.personal_number;
    if (kyc_type == "passport") {
      if (
        haveValue(user?.passport_id) &&
        document_number != user?.passport_id
      ) {
        // console.log("failed in passport")
        setIdent_all(false);
        return false;
      }
    } else {
      if (haveValue(user?.dni_nie) && personal_number != user?.dni_nie) {
        // console.log("failed in dnie")
        setIdent_all(false);
        return false;
      }
    }
    // console.log("failed no where")
    setIdent_all(true);
    return true;
  };

  const fetchFileData = async () => {
    let userid = UserId;
    const res = await fetchTenantDocs(userid);
    console.log(res);
    setTenantDocsData(res.data.success_res.tenantDocs);
  };

  const printDocument = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      printDocumentSafari();
    } else {
      printDocumentOthers();
    }
  };

  const printDocumentOthers = () => {
    var node = document.getElementById("divToPrint");
    const documentEle = document.getElementById("downloadpdf");
    if (haveValue(documentEle))
      documentEle.style.setProperty("display", "none", "important");
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        console.log("dataUrl -> ", dataUrl);
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const contentWidth = node.offsetWidth;
        const contentHeight = node.offsetHeight;
        // Calculate scale factors for width and height
        const scaleFactorWidth = pdfWidth / contentWidth;
        const scaleFactorHeight = pdfHeight / contentHeight;
        // Choose the smaller scale factor to fit content within A4 size
        const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);
        // Calculate image dimensions
        const imgWidth = contentWidth * scaleFactor;
        const imgHeight = contentHeight * scaleFactor;
        pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("SkorProfile.pdf");
        if (documentEle) {
          documentEle.style.removeProperty("display");
        }
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!", error);
        if (documentEle) {
          documentEle.style.removeProperty("display");
        }
      });
  };

  const printDocumentSafari = async () => {
    const node = document.getElementById("divToPrint");
    const documentEle = document.getElementById("downloadpdf");

    if (documentEle)
      documentEle.style.setProperty("display", "none", "important");

    try {
      document.querySelector(
        ".CircularProgressbar .CircularProgressbar-path"
      ).classList = "changeClass";
      const canvas = await html2canvas(node, { useCORS: true });
      const dataUrl = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;

      // Calculate scale factors for width and height
      const scaleFactorWidth = pdfWidth / contentWidth;
      const scaleFactorHeight = pdfHeight / contentHeight;

      // Choose the smaller scale factor to fit content within A4 size
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      // Calculate image dimensions
      const imgWidth = contentWidth * scaleFactor;
      const imgHeight = contentHeight * scaleFactor;

      document
        .querySelector(".CircularProgressbar .changeClass")
        .classList.add("CircularProgressbar-path");
      pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("SkorProfile.pdf");

      if (documentEle) documentEle.style.removeProperty("display");
    } catch (error) {
      document
        .querySelector(".CircularProgressbar .changeClass")
        .classList.add("CircularProgressbar-path");
      console.error("Error capturing element:", error);
    }
  };

  return (
    <>
      {haveValue(user) && (
        <div className="landlord-profile-edit" id="divToPrint">
          <div className="tabs-box-pattern">
            <div id="main-content">
              <div className="skor-qr-pdf">
                <div>
                  <img src="/images/SkorWhite.svg" alt="" />
                </div>
                <div className="pdf-qrcode">
                  <div>
                    <button
                      className="btn btngreen downloadpdf"
                      id="downloadpdf"
                      onClick={printDocument}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M4.5 0C3.25898 0 2.25 1.00898 2.25 2.25V5.625H4.5V2.25H12.4699L13.5 3.28008V5.625H15.75V3.28008C15.75 2.68242 15.5145 2.10938 15.0926 1.6875L14.0625 0.657422C13.6406 0.235547 13.0676 0 12.4699 0H4.5ZM13.5 12.375V15.75H4.5V12.375H13.5ZM15.75 13.5H16.875C17.4973 13.5 18 12.9973 18 12.375V9C18 7.75898 16.991 6.75 15.75 6.75H2.25C1.00898 6.75 0 7.75898 0 9V12.375C0 12.9973 0.502734 13.5 1.125 13.5H2.25V15.75C2.25 16.991 3.25898 18 4.5 18H13.5C14.741 18 15.75 16.991 15.75 15.75V13.5ZM15.1875 8.71875C15.4113 8.71875 15.6259 8.80764 15.7841 8.96588C15.9424 9.12411 16.0312 9.33872 16.0312 9.5625C16.0312 9.78628 15.9424 10.0009 15.7841 10.1591C15.6259 10.3174 15.4113 10.4062 15.1875 10.4062C14.9637 10.4062 14.7491 10.3174 14.5909 10.1591C14.4326 10.0009 14.3438 9.78628 14.3438 9.5625C14.3438 9.33872 14.4326 9.12411 14.5909 8.96588C14.7491 8.80764 14.9637 8.71875 15.1875 8.71875Z"
                          fill="#253837"
                        />
                      </svg>
                      {_t("Download PDF")}
                    </button>
                  </div>
                  <div className="qr-img">
                    <QRCode
                      id="qr_code_image"
                      value={`https://${process.env.REACT_APP_URL_NAME}/profile-request?sharedProfileLink=${user?._id}`}
                    />
                  </div>
                </div>
              </div>
              <div id="page-container" className="tab-content">
                <div id="home" className="tab-pane active padding-full-zero">
                  <div className="tabs-item d-block">
                    <div className="userscrollprofile-topbar">
                      {
                        <Row className="mx-0">
                          {haveValue(user?.gurantor_id) &&
                          haveValue(user?.gurantor_id?.paymentScores)
                            ? user?.gurantor_id?.paymentScores && (
                                <Col xl={6} className="mb-3">
                                  <div className="border-pd accordion-tile">
                                    <h4>{_t("Score")}</h4>
                                    <div className="process-bar-wappers process2 ">
                                      {user?.gurantor_id?.paymentScores && (
                                        <>
                                          <div className="gradient-progress-block">
                                            <svg style={{ height: 0 }}>
                                              <defs>
                                                <linearGradient
                                                  id="progressGradient"
                                                  gradientTransform="rotate(90)"
                                                >
                                                  <stop
                                                    offset="0%"
                                                    stopColor={"red"}
                                                  />
                                                  <stop
                                                    offset="50%"
                                                    stopColor={"yellow"}
                                                  />
                                                  <stop
                                                    offset="100%"
                                                    stopColor={"green"}
                                                  />
                                                </linearGradient>
                                              </defs>
                                            </svg>

                                            <CircularProgressbar
                                              width="75%"
                                              maxValue="1000"
                                              value={
                                                user?.gurantor_id?.paymentScores
                                                  .creditScore.value
                                              }
                                              // value={800}
                                              text={`${user?.gurantor_id?.paymentScores.creditScore.value}`}
                                              circleRatio={0.5}
                                              strokeWidth={6}
                                              styles={buildStyles({
                                                textColor: `${progressColor}`,
                                                pathColor: `${progressColor}`,
                                                rotation: 0.75,
                                                strokeLinecap: "round",
                                              })}
                                            />
                                          </div>
                                          <div className="name-box">
                                            <h3 className="score-name">
                                              {
                                                user?.gurantor_id?.kyc_details
                                                  ?.userDataByProvider[0]
                                                  ?.identity?.name
                                              }
                                              {"*"}
                                            </h3>
                                            <ul className="score-textgray">
                                              <span>
                                                *
                                                {_t(
                                                  "Bank Account Used For Scoring"
                                                )}{" "}
                                              </span>
                                            </ul>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              )
                            : user?.paymentScores && (
                                <Col xl={6} className="mb-3">
                                  <div className="border-pd accordion-tile">
                                    <h4>{_t("Score")}</h4>
                                    <div className="process-bar-wappers process2 ">
                                      {user?.paymentScores && (
                                        <>
                                          <div className="gradient-progress-block">
                                            <svg style={{ height: 0 }}>
                                              <defs>
                                                <linearGradient
                                                  id="progressGradient"
                                                  gradientTransform="rotate(90)"
                                                >
                                                  <stop
                                                    offset="0%"
                                                    stopColor={"red"}
                                                  />
                                                  <stop
                                                    offset="50%"
                                                    stopColor={"yellow"}
                                                  />
                                                  <stop
                                                    offset="100%"
                                                    stopColor={"green"}
                                                  />
                                                </linearGradient>
                                              </defs>
                                            </svg>

                                            <CircularProgressbar
                                              width="75%"
                                              maxValue="1000"
                                              value={
                                                user?.paymentScores.creditScore
                                                  .value
                                              }
                                              // value={800}
                                              text={`${user?.paymentScores.creditScore.value}`}
                                              circleRatio={0.5}
                                              strokeWidth={6}
                                              styles={buildStyles({
                                                textColor: `${progressColor}`,
                                                pathColor: `${progressColor}`,
                                                rotation: 0.75,
                                                strokeLinecap: "round",
                                              })}
                                            />
                                          </div>
                                          <div className="name-box">
                                            <h3 className="score-name">
                                              {
                                                user?.kyc_details
                                                  ?.userDataByProvider[0]
                                                  ?.identity?.name
                                              }
                                              {"*"}
                                            </h3>
                                            <ul className="score-textgray">
                                              <span>
                                                *
                                                {_t(
                                                  "Bank Account Used For Scoring"
                                                )}{" "}
                                              </span>
                                            </ul>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              )}
                          {}
                          <Col xl={6} className="mb-3">
                            <div className="score-summary border-pd prime-summary-sec accordion-tile">
                              <div>
                                <h4 className="">{_t("Summary")}</h4>
                                <div className="summary-checkes">
                                  <label className="rounded-check summaryinfobar">
                                    {user?.has_profile == true ? (
                                      <img src={tickNew} alt="" />
                                    ) : (
                                      <img src={crossNew} alt="" />
                                    )}
                                    {_t("Completed Profile")}
                                  </label>
                                  <label className="rounded-check summaryinfobar flexbarinner">
                                    <div className="d-flex items-center justify-content-start gap-2">
                                      {ident_all == true ? (
                                        <img src={tickNew} alt="" />
                                      ) : user?.onfido_user_kyc?.onfido_doc &&
                                        user?.onfido_user_kyc
                                          ?.onfido_workflow_res?.status !=
                                          "declined" ? (
                                        <img src={exclamation} alt="" />
                                      ) : (
                                        <img src={crossNew} alt="" />
                                      )}
                                      {_t("Verified identification")}
                                    </div>
                                  </label>
                                  <label className="rounded-check summaryinfobar">
                                    {haveValue(user?.gurantor_id) ? (
                                      haveValue(
                                        user?.gurantor_id?.paymentScores
                                      ) ? (
                                        <img src={tickNew} alt="" />
                                      ) : (
                                        <img src={crossNew} alt="" />
                                      )
                                    ) : haveValue(user?.paymentScores) ? (
                                      <img src={tickNew} alt="" />
                                    ) : (
                                      <img src={crossNew} alt="" />
                                    )}
                                    {_t("Verified bank account")}
                                  </label>
                                  <label className="rounded-check summaryinfobar">
                                    {recordedSalary?.matched >= 80 ? (
                                      <img src={tickNew} alt="" />
                                    ) : (
                                      <img src={crossNew} alt="" />
                                    )}
                                    {_t("Verified income")}
                                  </label>
                                  {user?.have_gurantor &&
                                    user?.have_gurantor == "true" && (
                                      <label className="rounded-check summaryinfobar">
                                        {haveGuarantor == true ||
                                        haveGuarantor == "true" ? (
                                          <img src={tickNew} alt="" />
                                        ) : (
                                          <img src={crossNew} alt="" />
                                        )}
                                        {_t("Guarantor")}
                                      </label>
                                    )}
                                </div>
                              </div>
                              {user?.onfido_user_kyc?.onfido_workflow_res
                                ?.status &&
                                user?.onfido_user_kyc?.onfido_workflow_res
                                  ?.status == "declined" && (
                                  <div className="gurantor_box kyc_failed_div">
                                    <p
                                      className="rounded-check set_gap_2"
                                      style={{ textAlign: "center" }}
                                    >
                                      <img src={crossNew} alt="" />
                                      {_t("KYC Failed")}!
                                    </p>
                                    <Row>
                                      <>
                                        <Col md={6}>
                                          {haveValue(
                                            user?.onfido_user_kyc?.onfido_error
                                          ) &&
                                            haveValue(
                                              user?.onfido_user_kyc
                                                ?.onfido_error[0]
                                            ) && (
                                              <p
                                                className="rounded-check"
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {
                                                  user?.onfido_user_kyc
                                                    ?.onfido_error[0]
                                                }
                                              </p>
                                            )}
                                        </Col>
                                        <Col md={6}>
                                          <p
                                            className="rounded-check"
                                            style={{
                                              textAlign: "center",
                                              textDecoration: "underline",
                                            }}
                                          ></p>
                                        </Col>
                                      </>
                                    </Row>
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col xl={6} className="mb-3">
                            <div className="score-personal-info border-pd accordion-tile">
                              <h4>{_t("Personal Info")}</h4>
                              <Row className="mx-0">
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Name")} :</h4>
                                    {user?.onfido_user_kyc?.onfido_doc_data
                                      ?.first_name ? (
                                      <h3>
                                        {`${user?.onfido_user_kyc?.onfido_doc_data?.first_name} ${user?.onfido_user_kyc?.onfido_doc_data?.last_name}`}{" "}
                                        <img src={tickNew} alt="" />
                                      </h3>
                                    ) : user?.fname ? (
                                      <h3>
                                        {`${user?.fname} ${user?.lname}`}{" "}
                                        {/* {checkName()} */}
                                      </h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                    <h3></h3>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Nationality")}</h4>
                                    {user?.nationality ? (
                                      <h3>
                                        {user?.nationality} {checkNationality()}
                                      </h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Passport ID")}</h4>
                                    {user?.passport_id ? (
                                      <h3>
                                        {user?.passport_id} {checkPassportKYC()}
                                      </h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                    {/* <h3>{user?.passport_id} </h3> */}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("DNI/NIE")}:</h4>
                                    {user?.dni_nie ? (
                                      <h3>
                                        {user?.dni_nie} {checkDNIKyc()}
                                      </h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                    {/* <h3>{user?.dni_nie}</h3> */}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Phone Number")}</h4>
                                    {user?.phone_number ? (
                                      <h3>{user?.phone_number}</h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Flatmates")}:</h4>
                                    {haveValue(user?.sharers) ? (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) =>
                                            navigate(
                                              "/edit-profile/tenant#add_flatmate_id"
                                            )
                                          }
                                        >
                                          {user?.sharers.length}{" "}
                                          {_t("Flatmates")}
                                        </a>
                                      </h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("+Add Flatmates")}
                                        </a>
                                      </h3>
                                    )}
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Email")}</h4>
                                    {user?.email ? (
                                      <div className="email_flex">
                                        <h3>{user?.email}</h3>
                                        <img src={tickNew} alt="" />
                                      </div>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                    {/* <h3>{user?.email} </h3> */}
                                  </div>
                                </Col>
                                {user?.have_gurantor == "true" &&
                                  haveValue(user?.gurantor_name) &&
                                  haveValue(user?.gurantor_email) && (
                                    <div className="gurantor_box">
                                      <h4>{_t("Guarantor Details")}</h4>
                                      <Row>
                                        {user?.have_gurantor == "true" &&
                                        haveValue(user?.gurantor_name) &&
                                        haveValue(user?.gurantor_email) ? (
                                          <>
                                            <Col md={6}>
                                              <div className="Score-content-list">
                                                <h4 className="d-flex align-items-center gap-1">
                                                  {_t("Name Of Guarantor")}
                                                </h4>

                                                {user?.gurantor_name ? (
                                                  <>
                                                    <h3>
                                                      {user?.gurantor_name}
                                                    </h3>
                                                  </>
                                                ) : (
                                                  <h3>{_t("No Guarantor")}</h3>
                                                )}
                                              </div>
                                            </Col>

                                            <Col md={6}>
                                              <div className="Score-content-list">
                                                <h4 className="d-flex align-items-center">
                                                  {_t("Guarantor Email")}:
                                                </h4>
                                                {user?.gurantor_email ? (
                                                  <h3>
                                                    {user?.gurantor_email}{" "}
                                                    {/* <span>{checkAddress()}</span> */}
                                                  </h3>
                                                ) : (
                                                  <h3 className="setNoInfoColor">
                                                    <a
                                                      href={undefined}
                                                      onClick={(e) =>
                                                        navigate(
                                                          "/edit-profile/tenant"
                                                        )
                                                      }
                                                    >
                                                      {_t("No Info")}
                                                    </a>
                                                  </h3>
                                                )}
                                              </div>
                                            </Col>
                                          </>
                                        ) : (
                                          haveValue(user?.gurantor_for) &&
                                          haveValue(
                                            user?.gurantor_for?._id
                                          ) && (
                                            <Col md={6}>
                                              <div className="Score-content-list">
                                                <h4>{_t("Guarantor Of")}:</h4>
                                                {user?.gurantor_for?.fname ? (
                                                  <h3>
                                                    {user?.gurantor_for?.fname}{" "}
                                                    {user?.gurantor_for?.lname}
                                                    <a href={undefined}>
                                                      <svg
                                                        width="7"
                                                        height="13"
                                                        viewBox="0 0 7 13"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M1 12L6 6.5L1 0.999999"
                                                          stroke="#EF7833"
                                                          stroke-width="2"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                        />
                                                      </svg>
                                                    </a>
                                                    {/* <span>{checkAddress()}</span> */}
                                                  </h3>
                                                ) : (
                                                  <h3 className="setNoInfoColor">
                                                    <a
                                                      href={undefined}
                                                      onClick={(e) =>
                                                        navigate(
                                                          "/edit-profile/tenant"
                                                        )
                                                      }
                                                    >
                                                      {_t("No Info")}
                                                    </a>
                                                  </h3>
                                                )}
                                              </div>
                                            </Col>
                                          )
                                        )}
                                      </Row>
                                    </div>
                                  )}
                              </Row>
                            </div>
                          </Col>
                          <Col xl={6} className="mb-3">
                            <div className="score-professional border-pd accordion-tile">
                              <h4>{_t("Professional and Academic")} </h4>
                              <Row className="mx-0">
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Employment Status")}:</h4>
                                    {user?.employment_status ? (
                                      <h3>{getEmploymentStatus()}</h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                  </div>
                                </Col>
                                {user?.employment_status ===
                                  constants.self_employed && (
                                  <>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Employment Type")}:</h4>
                                        {haveValue(user?.is_freelancer) ? (
                                          <h3>
                                            {user?.is_freelancer == true
                                              ? _t("Freelancer")
                                              : _t("Own a Company")}
                                          </h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("No Info")}
                                            </a>
                                          </h3>
                                        )}
                                      </div>
                                    </Col>
                                    {user?.is_freelancer == false && (
                                      <Col md={6}>
                                        <div className="Score-content-list">
                                          <h4>{_t("Company Name")}:</h4>
                                          {user?.name_of_company ? (
                                            <h3>{user?.name_of_company}</h3>
                                          ) : (
                                            <h3 className="setNoInfoColor">
                                              <a
                                                href={undefined}
                                                onClick={(e) =>
                                                  navigate(
                                                    "/edit-profile/tenant"
                                                  )
                                                }
                                              >
                                                {_t("No Info")}
                                              </a>
                                            </h3>
                                          )}
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Highest Education")}:</h4>
                                    {user?.type_of_student ? (
                                      <h3>{_t(user?.type_of_student)}</h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("University")}:</h4>
                                    {user?.name_of_university ? (
                                      <h3>{user?.name_of_university}</h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                  </div>
                                </Col>
                                {user?.employment_status ===
                                  constants.employed && (
                                  <>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Position")}:</h4>
                                        <h3>{user?.position} </h3>
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Name Of Company")}:</h4>
                                        <h3>
                                          {user?.name_of_company}{" "}
                                          <span>{checkCompanyname()}</span>{" "}
                                        </h3>
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>
                                          {_t("Email Of Previous Employer")}:
                                        </h4>
                                        <h3>
                                          {user?.email_previous_employer}{" "}
                                        </h3>
                                      </div>
                                    </Col>
                                  </>
                                )}

                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Annual Income")}:</h4>
                                    {user?.current_annual_income ? (
                                      <h3>
                                        {convertCurrencyFormat(
                                          user?.current_annual_income
                                        )}
                                      </h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("Verified Annual Income")}:</h4>
                                    {recordedSalary?.salary ? (
                                      <h3>
                                        {convertCurrencyFormat(
                                          recordedSalary?.salary
                                        )}
                                      </h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        {_t("No Info")}
                                      </h3>
                                    )}
                                    {/* <h3>{user?.current_annual_income} P.A.</h3> */}
                                  </div>
                                </Col>
                                {haveValue(user?.itr_status) && (
                                  <Col md={6}>
                                    <div className="Score-content-list">
                                      <h4>
                                        {_t("Verified Income from IRPF")}:
                                      </h4>
                                      {user?.itr_amount &&
                                      user?.itr_status == "success" ? (
                                        <h3>
                                          {convertCurrencyFormat(
                                            user?.itr_amount
                                          )}
                                          <img src={tickNew} alt="" />
                                        </h3>
                                      ) : user?.itr_status == "pending" ? (
                                        <h3>{_t("In Process")}</h3>
                                      ) : (
                                        <h3>
                                          {_t("Unable To Fetch The Amount")}
                                        </h3>
                                      )}
                                    </div>
                                  </Col>
                                )}
                                <Col md={6}>
                                  <div className="Score-content-list">
                                    <h4>{_t("LinkedIn Profile")}:</h4>
                                    {user?.linkedin_profile ? (
                                      <h3>{user?.linkedin_profile}</h3>
                                    ) : (
                                      <h3 className="setNoInfoColor">
                                        <a
                                          href={undefined}
                                          onClick={(e) => console.log("")}
                                        >
                                          {_t("No Info")}
                                        </a>
                                      </h3>
                                    )}
                                    {/* <h3>{user?.linkedin_profile} </h3> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      }
                    </div>
                    <Row className="card-listing pdf-listing">
                      {tenantDocsData?.map((eachData, index) => (
                        <Col md={3} key={eachData?._id}>
                          <Card>
                            <div className="videoframe">
                              {/* <iframe
                                                                src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                                                                scrolling="no"
                                                            /> */}
                              <PdfRender
                                pdfUrl={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                              />
                            </div>
                            <Card.Body>
                              <div className="listing-head">
                                <div className="head-block">
                                  <div className="leftbartext">
                                    <h5>{eachData?.type}</h5>
                                    <p>
                                      {eachData?.file_name
                                        .split("-")
                                        .slice(1)
                                        .join("-")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="listing-foot">
                                <span>
                                  {moment(eachData?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                    <div className="docoument-content">
                      <p>
                        {/* {
                                                    user_type == 'tenant' ?
                                                        _t("This is your Skor profile - a document that proves you have verified your financial solvency and which stores all your relevant documentation to access your new home in one place. You can conveniently share it with all your potential landlords and they can access your profile and documentation by scanning your tenant QR or link.")
                                                        :
                                                        _t("This document proves tenants have verified their identity and financial solvency with Skor. At Skor we use Open Banking and Machine Learning algorithms to generate a financial score between 0-1000 based on incomes and expense patterns and consistency and risk insights of this data to assess financial behavior.")
                                                } */}
                        {_t(`Skor is a rental documentation verification platform. This person has created their tenant profile. In this report you will be able to view each of the verification processes carried out and their results.

To access all the documentation and be able to download it, follow the following steps:`)}
                      </p>

                      <p>
                        {_t(
                          `1) Scan the QR code at the top or access this link.`
                        )}
                      </p>
                      <p>
                        {_t(
                          "2) Register in Skor as a landlord and automatically access this tenant's profile."
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePreview;
