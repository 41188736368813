import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../hooks/useTranslator";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { haveValue } from "../helper/common";
import AuthContext from "../context/auth/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import TenantContext from "../context/tenant/TenantContext";
import { Modal } from "react-bootstrap";

const StepForm = () => {
  const authContext = useContext(AuthContext);
  const {
    user,
    isLoggedIn,
    setLoading,
    loading,
    userType,
    loadUser,
    setLoggedInUser,
    toggleLogout,
  } = authContext;
  const tenantContext = useContext(TenantContext);
  const { getUniversityListing } = tenantContext;

  const navigate = useNavigate();

  const { _t } = useTranslator();
  const [step, setStep] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [idType, setIdType] = useState("dni_nie");
  const [goHomeConfirm, setGoHomeConfirm] = useState(false);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (localStorage.getItem("usertype") == "tenant") {
      setFormData((prevData) => ({
        ...prevData,
        ["phone_number"]: phoneNumber,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, ["phone"]: phoneNumber }));
    }
  }, [phoneNumber]);

  const applyProfileData = () => {
    setFormData({
      fname: user?.fname,
      lname: user?.lname,
      fname_companyName: user?.fname_companyName,
      password: "",
      nationality: "",
      dni_nie: "",
      passport_id: "",
      duration_type: "",
      years_of_living_in_country: "",
      rented_locally_before: "",
      email_previous_landlord: "",
      type_of_student: "",
      name_of_university: "",
      employment_status: "",
      position: "",
      name_of_company: "",
      current_annual_income: "",
      email_of_university: "",
      role: "",
      is_freelancer: "false",
      landlordtype: "",
      isLandlordInComp: "",
      company_name: "",
      email: user?.email,
      cif_dni_nie: "",
      phone: "",
      phone_number: "",
      current_course: "",
      name_of_current_university: "",
      university_end_date: "",
      have_sharer: "",
      sharers: [{ name: "", email: "" }],
      lang: localStorage.getItem("i18nextLng"),
    });
  };

  useEffect(() => {
    applyProfileData();
  }, [user]);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    loadUser(usertype);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setStep((prevStep) => prevStep + 1);
    setCurrentStep((prev) => prev + 1);
  };

  const handleGoBack = () => {
    setStep((prevStep) => prevStep - 1);
    setCurrentStep((prev) => prev - 1);
  };

  const handleGoBackNoProg = () => {
    setStep((prevStep) => prevStep - 1);
  };
  const handleNextNoProg = (e) => {
    e.preventDefault();
    setStep((prevStep) => prevStep + 1);
  };

  const handleIdTypeChange = (type) => {
    setIdType(type);
    if (type == "dni_nie") {
      setFormData((prevData) => ({ ...prevData, passport_id: "" }));
    } else {
      setFormData((prevData) => ({ ...prevData, dni_nie: "" }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const usertype = localStorage.getItem("usertype");
    formData.role = usertype;

    let userid = localStorage.getItem("saveduserid");
    createUserProfile(userid, formData);
  };

  const createUserProfile = async (id, profileData) => {
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`,
        profileData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then(async (res) => {
        setLoading(false);
        const usertype = localStorage.getItem("usertype");
        await setLoggedInUser();
        await loadUser(usertype);
        // navigate("/home");
        if (usertype == "tenant") {
          navigate("/tenant");
        } else {
          navigate("/home");
        }
      })
      .catch((err) => {
        setLoading(false);
        let serverResponse = err;
        toast.error(serverResponse.response.data.error);
      });
  };

  const handleNextAccType = (e) => {
    e.preventDefault();
    if (formData?.landlordtype == "agent") setStep((prevStep) => prevStep + 2);
    else setStep((prevStep) => prevStep + 1);
  };
  const handleNextLandlordType = (e) => {
    e.preventDefault();
    if (formData?.isLandlordInComp == "company")
      setStep((prevStep) => prevStep + 1);
    else {
      setStep((prevStep) => prevStep + 2);
      setCurrentStep((prev) => prev + 1);
    }
  };
  const handleBackCompanyDetails = (e) => {
    e.preventDefault();
    if (formData?.landlordtype == "agent") {
      setStep((prevStep) => prevStep - 2);
    } else setStep((prevStep) => prevStep - 1);
  };
  const handleGoBackPhone = (e) => {
    e.preventDefault();
    if (formData?.isLandlordInComp == "company")
      setStep((prevStep) => prevStep - 1);
    else setStep((prevStep) => prevStep - 2);
    setCurrentStep((prev) => prev - 1);
  };

  const handleGoHomeConfirm = () => {
    toggleLogout();
    navigate("/userrole");
  };

  // Function to scroll to the bottom of the div
  function scrollToBottom() {
    var scrollableDiv = document.getElementById("flatmate_div");
    if (haveValue(document.getElementById("flatmate_div")))
      scrollableDiv.scrollTop =
        scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
  }

  useEffect(() => {
    scrollToBottom();
  }, [formData?.sharers]);
  return (
    <>
      <div className="Auth-wrapper-2">
        <div className="logobarwrapper">
          <div className="logobar">
            <Container className="p-0 text-center">
              <div className="go-back-home">
                <a href={undefined} onClick={(e) => setGoHomeConfirm(true)}>
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="27"
                      cy="27"
                      r="26.5"
                      fill="#253837"
                      stroke="#A3EB64"
                    />
                    <path
                      d="M24.9821 22.1376L24.9884 22.1314L24.995 22.1256C25.0335 22.0911 25.0632 22.0505 25.0834 22.007C25.1035 21.9635 25.114 21.9173 25.1149 21.8711C25.1157 21.8249 25.1069 21.7785 25.0885 21.7346C25.07 21.6906 25.0419 21.6493 25.0049 21.6137C24.9679 21.578 24.9226 21.5489 24.8714 21.529C24.8202 21.5091 24.7647 21.4991 24.7085 21.5001C24.6522 21.501 24.5972 21.5129 24.5469 21.5344C24.4967 21.556 24.4527 21.5865 24.4172 21.6232L24.4111 21.6295L24.4047 21.6356L19.6098 26.2488C19.6098 26.2489 19.6097 26.2489 19.6097 26.2489C19.5371 26.319 19.5 26.4097 19.5 26.5C19.5 26.5903 19.5371 26.681 19.6097 26.7511C19.6097 26.7511 19.6098 26.7511 19.6098 26.7512L24.4047 31.3644L24.4111 31.3705L24.4172 31.3768C24.4527 31.4135 24.4967 31.444 24.5469 31.4656C24.5972 31.4871 24.6522 31.499 24.7085 31.4999C24.7647 31.5009 24.8202 31.4909 24.8714 31.471C24.9226 31.4511 24.9679 31.422 25.0049 31.3863C25.0419 31.3507 25.07 31.3094 25.0885 31.2654C25.1069 31.2215 25.1157 31.1751 25.1149 31.1289C25.114 31.0827 25.1035 31.0365 25.0834 30.993C25.0632 30.9495 25.0335 30.909 24.995 30.8744L24.9884 30.8686L24.9821 30.8624L21.7214 27.7253L20.8272 26.865L22.0681 26.865L33.6842 26.865C33.7962 26.865 33.8998 26.822 33.9733 26.7513C34.0462 26.6813 34.0833 26.5904 34.0833 26.5C34.0833 26.4096 34.0462 26.3187 33.9733 26.2487C33.8998 26.178 33.7962 26.135 33.6842 26.135L22.0681 26.135L20.8272 26.135L21.7214 25.2747L24.9821 22.1376Z"
                      fill="#FFB65A"
                      stroke="white"
                    />
                  </svg>
                  <span>{_t("Go Back To Home")} </span>
                </a>
              </div>
              <img src="../images/logo.svg" alt="logo" />
            </Container>
          </div>
          {localStorage.getItem("usertype") == "tenant" ? (
            <></>
          ) : (
            <></>
            // <div>
            //   <Container className="progress-bar">
            //     <ul className="progress-ul">
            //       <li
            //         className={`progress-li ${
            //           currentStep == 1 ? "current-progress" : ""
            //         }  ${currentStep > 1 ? "current-completed" : ""}`}
            //       ></li>
            //       <li
            //         className={`progress-li ${
            //           currentStep == 2 ? "current-progress" : ""
            //         }  ${currentStep > 2 ? "current-completed" : ""}`}
            //       ></li>
            //       <li
            //         className={`progress-li ${
            //           currentStep == 3 ? "current-progress" : ""
            //         }  ${currentStep > 3 ? "current-completed" : ""}`}
            //       ></li>
            //       <li
            //         className={`progress-li ${
            //           currentStep == 4 ? "current-progress" : ""
            //         }  ${currentStep > 4 ? "current-completed" : ""}`}
            //       ></li>
            //     </ul>
            //   </Container>
            // </div>
          )}
        </div>

        {localStorage.getItem("usertype") == "tenant" ? (
          <>
            <div className="stepformauthbar-inner">
              <div className="authbar-inner">
                <div className="fullbar">
                  <div className="right-contentbar">
                    {step === 1 && (
                      <div className="resend-text">
                        <h4>{_t("Complete Your Profile")}</h4>
                        <p>{_t("Please Enter Details")}</p>
                        <Form className="loginform" onSubmit={handleSubmit}>
                          <div className="auth-form">
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("First Name")}
                                <span className="mandatory_span">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                name="fname"
                                value={formData.fname}
                                onChange={handleInputChange}
                                required
                                placeholder={_t("Enter First Name")}
                              />
                            </div>
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("Last Name")}
                                <span className="mandatory_span">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                name="lname"
                                value={formData.lname}
                                onChange={handleInputChange}
                                required
                                placeholder={_t("Enter Last Name")}
                              />
                            </div>
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Phone Number")}
                                <span className="mandatory_span">*</span>
                              </label>
                              <PhoneInput
                                className="custom-input phone_input"
                                international
                                value={formData.phone_number}
                                onChange={setPhoneNumber}
                              />
                            </div>
                            <div className="btngroup row100">
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  !haveValue(formData.fname) ||
                                  !haveValue(formData.lname) ||
                                  !haveValue(formData.phone_number)
                                }
                              >
                                {_t("Submit")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {/* {step === 2 && (
                      <div className="resend-text">
                        <h4>{_t("What is Your nationality")}?</h4>
                        <p>{_t("Please enter Nationality")}</p>
                        <Form className="loginform" onSubmit={handleNext}>
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Nationality")}
                              </label>
                              <Select
                                options={options}
                                value={countrySelect}
                                onChange={handleNationalityChange}
                              />
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBack}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.nationality) ? false : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 3 &&
                      (idType == "dni_nie" ? (
                        <div className="resend-text">
                          <h4>{_t("enter DNI/NIE number")}</h4>
                          <p>{_t("Please enter your DNI/NIE Number")}</p>
                          <Form className="loginform" onSubmit={handleNext}>
                            <div className="auth-form">
                              <div className="forminput row100">
                                <label className="forminput">
                                  {_t("DNI/NIE Number")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control user-auth"
                                  name="dni_nie"
                                  value={formData.dni_nie}
                                  required
                                  onChange={handleInputChange}
                                  placeholder={_t("Enter DNI/NIE Number")}
                                />
                              </div>
                              <div className="orangetext">
                                <Link
                                  onClick={(e) =>
                                    handleIdTypeChange("passport_id")
                                  }
                                >
                                  {_t("Doesn’t have a DNI/NIE number")}?
                                </Link>
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={handleGoBack}
                                >
                                  {_t("Go Back")}
                                </button>
                                <button
                                  className="btn btnorange"
                                  type="submit"
                                  disabled={
                                    haveValue(formData.dni_nie) ? false : true
                                  }
                                >
                                  {_t("Next")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      ) : (
                        <div className="resend-text">
                          <h4>{_t("enter passport number")}</h4>
                          <p>{_t("Please enter your Passport Number")}</p>
                          <Form className="loginform" onSubmit={handleNext}>
                            <div className="auth-form">
                              <div className="forminput row100">
                                <label className="forminput">
                                  {_t("Passport Number")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control user-auth"
                                  name="passport_id"
                                  value={formData.passport_id}
                                  required
                                  onChange={handleInputChange}
                                  placeholder={_t("Enter Passport Number")}
                                />
                              </div>
                              <div className="orangetext">
                                <Link
                                  onClick={(e) => handleIdTypeChange("dni_nie")}
                                >
                                  {_t("Want to enter DNI/NIE number instead")}?
                                </Link>
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={handleGoBack}
                                >
                                  {_t("Go Back")}
                                </button>
                                <button
                                  className="btn btnorange"
                                  type="submit"
                                  disabled={
                                    haveValue(formData?.passport_id)
                                      ? false
                                      : true
                                  }
                                >
                                  {_t("Next")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      ))}
                    {step === 4 && (
                      <div className="resend-text">
                        <h4>{_t("Highest Completed Education")}</h4>
                        <p>{_t("Please enter Details")}</p>
                        <Form className="loginform" onSubmit={handleNext}>
                          <div className="auth-form">
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("Highest Education Level")}
                              </label>
                              <select
                                id="degree"
                                name="type_of_student"
                                value={formData.type_of_student}
                                onChange={handleInputChange}
                                className="form-control"
                              >
                                <option value="" disabled hidden>
                                  {_t("Select a degree")}
                                </option>
                                <option data-key="degree" value="Associate's">
                                  {_t("Associate's")}
                                </option>
                                <option data-key="degree" value="Bachelor's">
                                  {_t("Bachelor's")}
                                </option>
                                <option data-key="degree" value="Master's">
                                  {_t("Master's")}
                                </option>
                                <option data-key="degree" value="Doctorate">
                                  {_t("Doctorate")}
                                </option>
                                <option
                                  data-key="degree"
                                  value="Certificate / Diploma"
                                >
                                  {_t("Certificate / Diploma")}
                                </option>
                                <option
                                  data-key="degree"
                                  value="Graduate Certificates"
                                >
                                  {_t("Graduate Certificates")}
                                </option>
                                <option data-key="degree" value="Bootcamps">
                                  {_t("Bootcamps")}
                                </option>
                              </select>
                            </div>
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("Institution Name")}
                              </label>
                              <CreatableAsyncPaginate
                                value={uniSelect}
                                loadOptions={loadOptions}
                                className="form-control user-auth uni_select"
                                name="name_of_university"
                                onChange={handleSelectChange}
                                isCreatable={true}
                                formatCreateLabel={formatCreateLabel}
                              />
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBack}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.name_of_university) &&
                                  haveValue(formData.type_of_student)
                                    ? false
                                    : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 5 && (
                      <div className="resend-text">
                        <h4>{_t("What is Your Employment status")}</h4>
                        <p>{_t("Please select your answer")}</p>
                        <Form
                          className="loginform"
                          onSubmit={handleNextWorkStatus}
                        >
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Employment Status")}
                              </label>
                              <select
                                className="form-control"
                                name="employment_status"
                                value={formData?.employment_status}
                                onChange={handleInputChange}
                              >
                                <option value="" disabled hidden>
                                  {_t("Please Select")}
                                </option>
                                <option value={constants.employed}>
                                  {_t("Employed")}
                                </option>
                                <option value={constants.self_employed}>
                                  {_t("Self-employed")}
                                </option>
                                <option value={constants.student}>
                                  {_t("Student")}
                                </option>
                                <option value={constants.unemployed}>
                                  {_t("Unemployed")}{" "}
                                </option>
                              </select>
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBack}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData?.employment_status)
                                    ? false
                                    : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}

                    {step === 6 && (
                      <div className="resend-text">
                        <h4>{_t("Your Job Details")}</h4>
                        <p>{_t("Please enter your Job Details")}</p>
                        <Form
                          className="loginform"
                          onSubmit={(e) => {
                            setStep((prevStep) => prevStep + 2);
                            setCurrentStep((prev) => prev + 1);
                          }}
                        >
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Position")}
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control user-auth"
                                name="position"
                                value={formData.position}
                                onChange={handleInputChange}
                                placeholder={_t("Enter Position ")}
                              />
                            </div>
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Name Of Employer/Company")}
                              </label>
                              <input
                                type="text"
                                required
                                className="form-control user-auth"
                                name="name_of_company"
                                value={formData.name_of_company}
                                onChange={handleInputChange}
                                placeholder={_t("Enter Employer/Company ")}
                              />
                            </div>
                            <div className="forminput row100">
                              <label className="forminput annual_income_tooltip">
                                {_t("Annual Income")}
                                <ul className="infodorpdown summarydropdum">
                                  <li>
                                    <button className="iconinfo">
                                      <MdInfoOutline />
                                    </button>{" "}
                                  </li>
                                  <ul className="tooltip">
                                    <li>
                                      <p>
                                        {_t(
                                          "Enter your annual income accurately to help us match and verify your banking records for a seamless verification process"
                                        )}
                                        .
                                      </p>
                                    </li>
                                  </ul>
                                </ul>
                              </label>
                              <input
                                type="number"
                                required
                                className="form-control user-auth"
                                name="current_annual_income"
                                value={formData.current_annual_income}
                                onChange={handleInputChange}
                                placeholder={_t("Enter Income ")}
                              />
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBackNoProg}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.position) &&
                                  haveValue(formData.name_of_company) &&
                                  haveValue(formData.current_annual_income)
                                    ? false
                                    : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 7 && (
                      <div className="resend-text">
                        {formData.employment_status == constants.student ? (
                          <h4>{_t("Institution Details")}</h4>
                        ) : (
                          <h4>{_t("Income Details")}</h4>
                        )}
                        <p>{_t("Please enter your Details")}</p>
                        {formData.employment_status == constants.student && (
                          <Form
                            className="loginform"
                            onSubmit={handleEmailFormat}
                          >
                            <div className="auth-form">
                              <div className="forminput row50">
                                <label className="forminput">
                                  {_t("Education Level")}
                                </label>
                                <select
                                  id="degree"
                                  name="current_course"
                                  value={formData.current_course}
                                  onChange={handleInputChange}
                                  className="form-control"
                                >
                                  <option value="" disabled hidden>
                                    Select a degree
                                  </option>
                                  <option data-key="degree" value="Associate's">
                                    Associate's
                                  </option>
                                  <option data-key="degree" value="Bachelor's">
                                    Bachelor's
                                  </option>
                                  <option data-key="degree" value="Master's">
                                    Master's
                                  </option>
                                  <option data-key="degree" value="Doctorate">
                                    Doctorate
                                  </option>
                                  <option
                                    data-key="degree"
                                    value="Certificate / Diploma"
                                  >
                                    Certificate / Diploma
                                  </option>
                                  <option
                                    data-key="degree"
                                    value="Graduate Certificates"
                                  >
                                    Graduate Certificates
                                  </option>
                                  <option data-key="degree" value="Bootcamps">
                                    Bootcamps
                                  </option>
                                </select>
                              </div>
                              <div className="forminput row50">
                                <label className="forminput">
                                  {_t("Institution")}
                                </label>
                                <CreatableAsyncPaginate
                                  value={currUniSelect}
                                  loadOptions={loadOptions}
                                  className="form-control user-auth uni_select"
                                  name="name_of_current_university"
                                  onChange={handleCurrSelectChange}
                                  isCreatable={true}
                                  formatCreateLabel={formatCreateLabel}
                                />
                              </div>
                              <div className="forminput row100">
                                <label className="forminput ">
                                  {_t("Institution Email")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control user-auth"
                                  name="email_of_university"
                                  value={formData.email_of_university}
                                  onChange={handleInputChange}
                                  required
                                  placeholder={_t(
                                    "Enter Your Institution Email"
                                  )}
                                />
                              </div>
                              <div className="forminput row50">
                                <label className="forminput ">
                                  {_t("Expected Course End Date")}
                                </label>
                                <input
                                  type="date"
                                  className="form-control user-auth"
                                  name="university_end_date"
                                  value={formData.university_end_date}
                                  onChange={handleInputChange}
                                  required
                                  // placeholder={_t("Enter Your Institution Email")}
                                />
                              </div>
                              <div className="forminput row50">
                                <label className="forminput ">
                                  {_t("Current Annual Income")}
                                </label>
                                <input
                                  type="number"
                                  className="form-control user-auth"
                                  name="current_annual_income"
                                  value={formData.current_annual_income}
                                  onChange={handleInputChange}
                                  placeholder={_t(
                                    "Enter Income (If Applicable)"
                                  )}
                                />
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={(e) =>
                                    setStep((prevStep) => prevStep - 2)
                                  }
                                >
                                  {_t("Go Back")}
                                </button>
                                <button
                                  disabled={
                                    haveValue(formData.current_course) &&
                                    haveValue(
                                      formData.name_of_current_university
                                    ) &&
                                    haveValue(formData.email_of_university) &&
                                    haveValue(formData.university_end_date)
                                      ? false
                                      : true
                                  }
                                  className="btn btnorange"
                                  type="submit"
                                >
                                  {_t("Next")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                        {formData.employment_status == constants.unemployed && (
                          <Form className="loginform" onSubmit={handleNext}>
                            <div className="auth-form">
                              <div className="forminput row100">
                                <label className="forminput annual_income_tooltip">
                                  {_t("Annual Income")}
                                  <ul className="infodorpdown summarydropdum">
                                    <li>
                                      <button className="iconinfo">
                                        <MdInfoOutline />
                                      </button>{" "}
                                    </li>
                                    <ul className="tooltip">
                                      <li>
                                        <p>
                                          {_t(
                                            "Enter your annual income accurately to help us match and verify your banking records for a seamless verification process"
                                          )}
                                          .
                                        </p>
                                      </li>
                                    </ul>
                                  </ul>
                                </label>
                                <input
                                  type="number"
                                  required
                                  className="form-control user-auth"
                                  name="current_annual_income"
                                  value={formData.current_annual_income}
                                  onChange={handleInputChange}
                                  placeholder={_t("Enter")}
                                />
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={(e) =>
                                    setStep((prevStep) => prevStep - 2)
                                  }
                                >
                                  {_t("Go Back")}
                                </button>
                                <button
                                  disabled={
                                    haveValue(formData.current_annual_income)
                                      ? false
                                      : true
                                  }
                                  className="btn btnorange"
                                  type="submit"
                                >
                                  {_t("Next")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                        {formData.employment_status ==
                          constants.self_employed && (
                          <Form className="loginform" onSubmit={handleNext}>
                            <div className="auth-form">
                              <div className="forminput row100">
                                <label className="forminput">
                                  {_t("Employment Type")}
                                </label>
                                <div className="customradio_box height-equal">
                                  <label className="forminput">
                                    <input
                                      type="radio"
                                      required
                                      className="user-auth"
                                      name="is_freelancer"
                                      checked={
                                        formData.is_freelancer == "false"
                                      }
                                      value={false}
                                      onChange={handleInputChange}
                                      placeholder={_t("Enter")}
                                    />
                                    {_t("Own a Company")}
                                  </label>
                                  <label className="forminput">
                                    <input
                                      type="radio"
                                      required
                                      className="user-auth"
                                      name="is_freelancer"
                                      checked={formData.is_freelancer == "true"}
                                      value={true}
                                      onChange={handleInputChange}
                                      placeholder={_t("Enter")}
                                    />
                                    {_t("Freelancer")}
                                  </label>
                                </div>
                              </div>
                              {formData.is_freelancer == "false" && (
                                <div className="forminput row100">
                                  <label className="forminput">
                                    {_t("Company Name")}
                                  </label>
                                  <input
                                    type="text"
                                    required
                                    className="form-control user-auth"
                                    name="name_of_company"
                                    value={formData.name_of_company}
                                    onChange={handleInputChange}
                                    placeholder={_t("Enter")}
                                  />
                                </div>
                              )}
                              <div className="forminput row100">
                                <label className="forminput annual_income_tooltip">
                                  {_t("Annual Income")}
                                  <ul className="infodorpdown summarydropdum">
                                    <li>
                                      <button className="iconinfo">
                                        <MdInfoOutline />
                                      </button>{" "}
                                    </li>
                                    <ul className="tooltip">
                                      <li>
                                        <p>
                                          {_t(
                                            "Enter your annual income accurately to help us match and verify your banking records for a seamless verification process"
                                          )}
                                          .
                                        </p>
                                      </li>
                                    </ul>
                                  </ul>
                                </label>
                                <input
                                  type="number"
                                  required
                                  className="form-control user-auth"
                                  name="current_annual_income"
                                  value={formData.current_annual_income}
                                  onChange={handleInputChange}
                                  placeholder={_t("Enter")}
                                />
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={(e) =>
                                    setStep((prevStep) => prevStep - 2)
                                  }
                                >
                                  {_t("Go Back")}
                                </button>
                                <button
                                  disabled={
                                    haveValue(formData.current_annual_income)
                                      ? false
                                      : true
                                  }
                                  className="btn btnorange"
                                  type="submit"
                                >
                                  {_t("Next")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </div>
                    )}
                    {step === 8 && (
                      <div className="resend-text">
                        <h4>{_t("How Long Are You Living in Spain")}?</h4>
                        <p>{_t("Please enter your Details")}</p>
                        <Form className="loginform" onSubmit={handleNext}>
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Time Lived In Spain")}
                              </label>
                              <div className="input-dropdown-inline ">
                                <input
                                  type="number"
                                  className="form-control user-auth"
                                  required
                                  name="years_of_living_in_country"
                                  value={formData.years_of_living_in_country}
                                  onChange={handleInputChange}
                                  placeholder={_t(
                                    "Enter Years Living In country"
                                  )}
                                />
                                <select
                                  required
                                  className="form-control w-100"
                                  name="duration_type"
                                  value={formData.duration_type}
                                  onChange={handleInputChange}
                                >
                                  <option value="" disabled hidden>
                                    {_t("Select")}
                                  </option>
                                  <option value="months">{_t("Months")}</option>
                                  <option value="years">{_t("Years")}</option>
                                </select>
                              </div>
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBackCheck}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.duration_type) &&
                                  haveValue(formData.years_of_living_in_country)
                                    ? false
                                    : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 9 && (
                      <div className="resend-text">
                        <h4>{_t("Have you rented before")}?</h4>
                        <p>{_t("Please select your answer")}</p>
                        <Form
                          className="loginform"
                          onSubmit={handleNextRentedBefore}
                        >
                          <div className="auth-form">
                            <div className="forminput row100 inlinecheckboxes">
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="rented_locally_before"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.rented_locally_before == "yes"
                                        ? true
                                        : false
                                    }
                                    value="yes"
                                  />
                                  <div class="boxes">{_t("Yes, I Have")}</div>
                                </label>
                              </div>
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="rented_locally_before"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.rented_locally_before == "no"
                                        ? true
                                        : false
                                    }
                                    value="no"
                                  />
                                  <div class="boxes">{_t("No, I Haven’t")}</div>
                                </label>
                              </div>
                            </div>

                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBack}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.rented_locally_before)
                                    ? false
                                    : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 10 && (
                      <div className="resend-text">
                        <h4>{_t("Email of previous landlord")}</h4>
                        <p>{_t("Please enter Details")}</p>
                        <Form className="loginform" onSubmit={handleNext}>
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Enter Email")}
                              </label>
                              <input
                                type="email"
                                className="form-control user-auth"
                                name="email_previous_landlord"
                                onChange={handleInputChange}
                                value={formData.email_previous_landlord}
                                placeholder={_t("Optional")}
                              />
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBackNoProg}
                              >
                                {_t("Go Back")}
                              </button>
                              <button className="btn btnorange" type="submit">
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 11 && (
                      <div className="resend-text">
                        <h4>{_t("Do you have a Flatmate")}</h4>
                        <p>{_t("Please select your answer")}</p>
                        <Form
                          className="loginform"
                          onSubmit={
                            formData?.have_sharer == "yes"
                              ? handleNextNoProg
                              : handleSubmit
                          }
                        >
                          <div className="auth-form">
                            <div className="forminput row100 inlinecheckboxes">
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="have_sharer"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.have_sharer == "yes"
                                        ? true
                                        : false
                                    }
                                    value="yes"
                                  />
                                  <div class="boxes">{_t("Yes, I Have")}</div>
                                </label>
                              </div>
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="have_sharer"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.have_sharer == "no"
                                        ? true
                                        : false
                                    }
                                    value="no"
                                  />
                                  <div class="boxes">
                                    {_t("No, I Have Not")}
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBack}
                              >
                                {_t("Go Back")}
                              </button>
                              <button className="btn btnorange" type="submit">
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 12 && (
                      <div className="resend-text">
                        <h4>{_t("Flatmate Details")}</h4>
                        <p>{_t("Please enter your Flatmate Details")}</p>
                        <Form
                          className="loginform"
                          onSubmit={checkFlatmateEmail}
                        >
                          <div className="auth-form">
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("Name of Flatmate")}
                              </label>
                            </div>
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("Email of Flatmate")}
                              </label>
                            </div>
                            <div id="flatmate_div" className="row100">
                              {formData.sharers.map((sharer, index) => (
                                <div
                                  id={`flatmate_div_${index}`}
                                  className="row100"
                                  key={index}
                                >
                                  <div className="row50">
                                    <input
                                      type="text"
                                      className="form-control user-auth"
                                      name="name"
                                      value={sharer.name}
                                      onChange={(e) =>
                                        handleSharerChange(index, e)
                                      }
                                      required
                                      placeholder="Enter Name"
                                    />
                                  </div>
                                  <div className="row50">
                                    <input
                                      type="text"
                                      className="form-control user-auth"
                                      name="email"
                                      value={sharer.email}
                                      onChange={(e) =>
                                        handleSharerChange(index, e)
                                      }
                                      required
                                      placeholder="Enter Email"
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="btngroup row100 gridbar fullwidthbtn">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleAddMoreComponent}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.4616 7.46154H8.53862V1.53846C8.53862 1.39565 8.4819 1.25869 8.38092 1.15771C8.27994 1.05673 8.14298 1 8.00018 1C7.85737 1 7.72041 1.05673 7.61944 1.15771C7.51846 1.25869 7.46173 1.39565 7.46173 1.53846V7.46154H1.5388C1.396 7.46154 1.25904 7.51827 1.15806 7.61925C1.05708 7.72023 1.00035 7.85719 1.00035 8C0.997696 8.06998 1.01007 8.13972 1.03664 8.20451C1.06321 8.26931 1.10336 8.32765 1.15439 8.37562C1.20542 8.42358 1.26613 8.46005 1.33244 8.48257C1.39875 8.50509 1.46912 8.51313 1.5388 8.50615H7.46173V14.4615C7.46173 14.6043 7.51846 14.7413 7.61944 14.8423C7.72041 14.9433 7.85737 15 8.00018 15C8.14298 15 8.27994 14.9433 8.38092 14.8423C8.4819 14.7413 8.53862 14.6043 8.53862 14.4615V8.53846H14.4616C14.6044 8.53846 14.7413 8.48173 14.8423 8.38075C14.9433 8.27977 15 8.14281 15 8C15 7.85719 14.9433 7.72023 14.8423 7.61925C14.7413 7.51827 14.6044 7.46154 14.4616 7.46154Z"
                                    fill="#253837"
                                    stroke="#253837"
                                  />
                                </svg>
                                {_t("Add More")}
                              </button>
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBackNoProg}
                              >
                                {_t("Go Back")}
                              </button>
                              <button className="btn btnorange" type="submit">
                                {_t("Submit")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="stepformauthbar-inner">
              <div className="authbar-inner">
                <div className="fullbar">
                  <div className="right-contentbar">
                    {step === 1 && (
                      <div className="resend-text">
                        <h4>{_t("What is your name")}?</h4>
                        <p>{_t("Please enter your name")}</p>
                        <Form className="loginform" onSubmit={handleSubmit}>
                          <div className="auth-form">
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("First Name")}
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                name="fname_companyName"
                                value={formData.fname_companyName}
                                onChange={handleInputChange}
                                required
                                placeholder={_t("Enter First Name")}
                              />
                            </div>
                            <div className="forminput row50">
                              <label className="forminput">
                                {_t("Last Name")}
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                name="lname"
                                value={formData.lname}
                                onChange={handleInputChange}
                                required
                                placeholder={_t("Enter Last Name")}
                              />
                            </div>
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Phone Number")}
                                <span className="mandatory_span">*</span>
                              </label>
                              <PhoneInput
                                className="custom-input"
                                international
                                // defaultCountry="RU"
                                value={formData.phone}
                                onChange={setPhoneNumber}
                              />
                            </div>
                            <div className="btngroup row100">
                              <button className="btn btnorange" type="submit">
                                {_t("Submit")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 2 && (
                      <div className="resend-text">
                        <h4>{_t("Create Account As")}?</h4>
                        <p>{_t("Please select your answer")}</p>
                        <Form
                          className="loginform"
                          onSubmit={handleNextAccType}
                        >
                          <div className="auth-form">
                            <div className="forminput row100 inlinecheckboxes">
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="landlordtype"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.landlordtype == "agent"
                                        ? true
                                        : false
                                    }
                                    value="agent"
                                  />
                                  <div class="boxes">{_t("Agent")}</div>
                                </label>
                              </div>
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="landlordtype"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.landlordtype == "landlord"
                                        ? true
                                        : false
                                    }
                                    value="landlord"
                                  />
                                  <div class="boxes">{_t("Landlord")}</div>
                                </label>
                              </div>
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBack}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.landlordtype)
                                    ? false
                                    : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 3 && (
                      <div className="resend-text">
                        <h4>{_t("Landlord Type")}?</h4>
                        <p>{_t("Please select your answer")}</p>
                        <Form
                          className="loginform"
                          onSubmit={handleNextLandlordType}
                        >
                          <div className="auth-form">
                            <div className="forminput row100 inlinecheckboxes">
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="isLandlordInComp"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.isLandlordInComp == "company"
                                        ? true
                                        : false
                                    }
                                    value="company"
                                  />
                                  <div class="boxes">{_t("Company")}</div>
                                </label>
                              </div>
                              <div className="uilabel">
                                <label class="customradiobar">
                                  <input
                                    type="radio"
                                    name="isLandlordInComp"
                                    onChange={handleInputChange}
                                    checked={
                                      formData?.isLandlordInComp == "individual"
                                        ? true
                                        : false
                                    }
                                    value="individual"
                                  />
                                  <div class="boxes">{_t("Individual")}</div>
                                </label>
                              </div>
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBackNoProg}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.isLandlordInComp)
                                    ? false
                                    : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 4 && (
                      <div className="resend-text">
                        <h4>{_t("Your company details")}?</h4>
                        <p>{_t("Please Enter details")}</p>
                        <Form className="loginform" onSubmit={handleNext}>
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Company Name")}
                              </label>
                              <input
                                type="text"
                                className="form-control user-auth"
                                required
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleInputChange}
                                placeholder={_t("Enter Company Name")}
                              />
                            </div>
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Company Email")}
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control user-auth"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder={_t("Enter Company Email")}
                              />
                            </div>
                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleBackCompanyDetails}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.company_name) &&
                                  haveValue(formData.email)
                                    ? false
                                    : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 5 && (
                      <div className="resend-text">
                        <h4>{_t("Enter Your Phone number")}</h4>
                        <p>{_t("Please Enter Details")}</p>
                        <Form className="loginform" onSubmit={handleNext}>
                          <div className="auth-form">
                            <div className="forminput row100">
                              <label className="forminput">
                                {_t("Phone Number")}
                              </label>
                              <PhoneInput
                                className="custom-input"
                                international
                                // defaultCountry="RU"
                                value={formData.phone}
                                onChange={setPhoneNumber}
                              />
                            </div>

                            <div className="btngroup row100 gridbar">
                              <button
                                className="btn btnoutline"
                                type="button"
                                onClick={handleGoBackPhone}
                              >
                                {_t("Go Back")}
                              </button>
                              <button
                                className="btn btnorange"
                                type="submit"
                                disabled={
                                  haveValue(formData.phone) ? false : true
                                }
                              >
                                {_t("Next")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {step === 6 &&
                      (formData?.isLandlordInComp == "company" ? (
                        <div className="resend-text">
                          <h4>{_t("Enter NIF number")}</h4>
                          <p>{_t("Please Enter Your NIF Number")}</p>
                          <Form className="loginform" onSubmit={handleSubmit}>
                            <div className="auth-form">
                              <div className="forminput row100">
                                <label className="forminput">
                                  {_t("NIF Number")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control user-auth"
                                  name="cif_dni_nie"
                                  onChange={handleInputChange}
                                  value={formData.cif_dni_nie}
                                  placeholder={_t("Enter NIF Number")}
                                />
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={handleGoBack}
                                >
                                  {_t("Go Back")}
                                </button>
                                <button
                                  className="btn btnorange"
                                  type="submit"
                                  disabled={
                                    haveValue(formData.cif_dni_nie)
                                      ? false
                                      : true
                                  }
                                >
                                  {_t("Submit")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      ) : (
                        <div className="resend-text">
                          <h4>{_t("Enter DNI/NIE number")}</h4>
                          <p>{_t("Please Enter Your DNI/NIE Number")}</p>
                          <Form className="loginform" onSubmit={handleSubmit}>
                            <div className="auth-form">
                              <div className="forminput row100">
                                <label className="forminput">
                                  {_t("DNI/NIE Number")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control user-auth"
                                  name="cif_dni_nie"
                                  onChange={handleInputChange}
                                  value={formData.cif_dni_nie}
                                  placeholder={_t("Enter DNI/NIE Number")}
                                />
                              </div>
                              <div className="btngroup row100 gridbar">
                                <button
                                  className="btn btnoutline"
                                  type="button"
                                  onClick={handleGoBack}
                                >
                                  {_t("Go Back")}
                                </button>
                                <button
                                  className="btn btnorange"
                                  type="submit"
                                  disabled={
                                    haveValue(formData.cif_dni_nie)
                                      ? false
                                      : true
                                  }
                                >
                                  {_t("Submit")}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Modal
          show={goHomeConfirm}
          onHide={(e) => setGoHomeConfirm(false)}
          centered
          className="newmodalui asguarantor"
        >
          <Modal.Header>
            <Modal.Title>{_t("Go Back To Home")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="forminput row100">
              {_t(
                "Warning: Continuing will result in the loss of your current information"
              )}
              .
            </div>
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline cancel-btn"
                type="button"
                onClick={(e) => setGoHomeConfirm(false)}
              >
                {_t("Cancel")}
              </button>
              <button
                className="btn btnorange"
                type="button"
                onClick={handleGoHomeConfirm}
              >
                {_t("Go Home")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Container>
          <div className="terms-bar">
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/terms-conditions.html"
              >
                {_t("Terms & Conditions")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/terms-conditions.html"
              >
                {_t("Terms & Conditions")}
              </Link>
            )}
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/privacy-policy.html"
              >
                {_t("Privacy Policy")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/privacy-policy.html"
              >
                {_t("Privacy Policy")}
              </Link>
            )}
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/cookies.html"
              >
                {_t("Cookies Policy")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/cookies.html"
              >
                {_t("Cookies Policy")}
              </Link>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default StepForm;
