import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import LandLordContext from "../../../context/landlord/LandLordContext";
import AcceptAssignedProp from "../../../components/Tenant/AcceptAssignedProp";
import { haveValue } from "../../../helper/common";
import ReviewAssignProp from "../../../components/Tenant/ReviewAssignedProp";
import { useCookies } from "react-cookie";
import RentalSetup from "../../../components/Tenant/RentalSetup";
import TenantContext from "../../../context/tenant/TenantContext";
import constants from "../../../Utils/constants";



function AcceptProperty() {

    const navigate = useNavigate()
    const { assignedPropertyId } = useParams()
    const [formData, setFormData] = useState([])
    const landLordContext = useContext(LandLordContext);
    const { fetchAssignedDetails } = landLordContext
    const tenantContext = useContext(TenantContext);
    const { checkUelzStatus } = tenantContext
    const queryParameters = new URLSearchParams(window.location.search)
    const paramStep = queryParameters.get('step')
    const [step, setStep] = useState(haveValue(paramStep) ? paramStep : 1);
    const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink'])


    useEffect(() => {

        if (haveValue(cookies.assignedPropertyId)) {
            console.log('REMOVING COOKIE', window.location.pathname)
            removeCookie('assignedPropertyId', { maxAge: 1800 })
        }
        fetchAssignedDetails(assignedPropertyId)
            .then(res => {
                console.log(res.result, "CHECK THE RESULT HERE")
                setFormData(res.result);
            })
            .catch(err => {
                console.log(err);
            })

        checkUelzStatus(assignedPropertyId)
            .then(res => {
                if (res?.success_res?.data?.status == constants.COMPLETED) {
                    navigate(`/review/rental/${assignedPropertyId}`)
                }
            })
            .catch(err => {
                console.log(err);
            })

    }, [])

    const handleNextStep = () => {
        navigate(`/accept/assigned/property/${assignedPropertyId}?step=${+step + 1}`)
        setStep(prev => prev + 1)

    }


    return (
        <>
            {
                step == 1 && <AcceptAssignedProp handleNextStep={handleNextStep} formData={formData} />
            }
            {
                step == 2 && haveValue(formData?._id) && <ReviewAssignProp handleNextStep={handleNextStep} formData={formData} />
            }
            {
                step == 3 && haveValue(formData?._id) && <RentalSetup handleNextStep={handleNextStep} formData={formData} setFormData={setFormData} />
            }
        </>
    )
}

export default AcceptProperty