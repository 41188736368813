import { useContext, useState } from "react";
import constants from "../../Utils/constants";
import useTranslator from "../../hooks/useTranslator";
import moment from "moment";
import LandLordContext from "../../context/landlord/LandLordContext";
import { toast } from "react-toastify";

const RentalDate = (props) => {
  const { step, handleNextStep, formData, setStep, setFormData } = props;
  const { _t } = useTranslator();

  const landLordContext = useContext(LandLordContext);
  const { initiateAssignProperty } = landLordContext;
  const handleKeyDown = (event) => {
    event.preventDefault();
  };

  const calculatePaymentDate = (date) => {
    const todayDate = new Date();
    var currentYear = todayDate.getFullYear();
    var currentMonth = todayDate.getMonth();
    var currPayDay = new Date(currentYear, currentMonth, date);

    if (
      currPayDay < todayDate ||
      currPayDay < new Date(formData?.payment_start)
    ) {
      while (
        currPayDay < todayDate ||
        currPayDay < new Date(formData?.payment_start)
      ) {
        currentMonth++;

        if (currentMonth > 11) {
          currentMonth = 0; // January
          currentYear++;
        }
        currPayDay = new Date(currentYear, currentMonth, date);
      }

      return currPayDay.toLocaleDateString("en-GB");
    } else {
      return currPayDay.toLocaleDateString("en-GB");
    }
  };

  const setPaymentDate = (date) => {
    setFormData((prev) => ({ ...prev, payment_date: date }));
  };

  const handleSelectDate = () => {
    const temp = { ...formData };
    temp.edit_id = temp?._id;
    initiateAssignProperty(temp)
      .then((res) => {
        console.log(res);
        setStep((prev) => prev + 1);
      })
      .catch((err) => {
        console.log(err);
        toast.error(_t("Error in setting payment date!"));
      });
  };

  return (
    <>
      {step == 1 ? (
        <>
          <div className="rentaldetails-body main-rentaldetails">
            <div className="step-point rental-step-point">
              <p>3</p>
              <h3>{"Rent Payments"}</h3>
            </div>
            <div className="skorfull-deposit">
              <div className="skor-depositsec">
                <div className="skorflex-rent">
                  <h6>{_t("Skor Flex Rent")}</h6>
                  <p>
                    <del> {_t("Payment between 1st - 5th of the Month")} </del>
                  </p>
                  <p className="choosedate-text">
                    {" "}
                    {_t("Choose the Date you want!")}{" "}
                  </p>

                  <div className="paymentdate-sec">
                    <label htmlFor="payment_date">
                      {" "}
                      {"Payment Date"} <span>*</span>{" "}
                    </label>
                    <br></br>
                    <input
                      disabled
                      className="choose-date"
                      id="payment_date"
                      type="date"
                    />
                    <br></br>
                    {/* onChange={e => setPaymentDate(new Date(e.target.value).getDate())} */}
                    <p>
                      {_t("The payment of")} {formData?.rent_amount}€{" "}
                      {_t("will start monthly on ")} {formData?.payment_date}
                    </p>
                  </div>
                </div>
                <button className="grey-pay">
                  <span>{_t("Pay Flexible Rent")}</span>
                </button>
              </div>
              <div className="skor-depositsec">
                <div className="regular-rent">
                  <h6>{_t("Regular Rent")}</h6>
                  <div className="paymentbetween">
                    <p> {_t("Payment between 1st - 5th of the Month")} </p>
                    <p className="regularrent-amount">
                      {" "}
                      {formData?.rent_amount}€{" "}
                    </p>
                  </div>
                  <div className="paymentdate-sec">
                    <label htmlFor="payment_date"> {"Payment Date"} </label>
                    <br></br>
                    <input
                      className="choose-date"
                      id="payment_date"
                      onChange={(e) =>
                        setPaymentDate(new Date(e.target.value).getDate())
                      }
                      type="date"
                      onKeyDown={handleKeyDown}
                      value={moment(
                        calculatePaymentDate(formData?.payment_date),
                        "DD/MM/YYYY"
                      ).format("YYYY-MM-DD")}
                      min={moment(
                        calculatePaymentDate("1"),
                        "DD/MM/YYYY"
                      ).format("YYYY-MM-DD")}
                      max={moment(
                        calculatePaymentDate("5"),
                        "DD/MM/YYYY"
                      ).format("YYYY-MM-DD")}
                    />
                    {/*  */}
                    <p>
                      {_t("The payment of")} {+formData?.rent_amount}€{" "}
                      {_t("will start monthly on ")} {formData?.payment_date}
                    </p>
                  </div>
                </div>
                <button
                  className="btn btngreen w-100"
                  onClick={handleSelectDate}
                >
                  {_t("Pay Regular Rent")}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="depositoptions-sec">
            <div className="rent-left">
              <div className="check-rent">
                {step > 1 ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <circle cx="16" cy="16" r="16" fill="#A3EB64" />
                      <path
                        d="M11.8654 20.314L7.93019 16.4506C7.71814 16.2425 7.43055 16.1255 7.13068 16.1255C6.8308 16.1255 6.54321 16.2425 6.33117 16.4506C6.11912 16.6588 6 16.9412 6 17.2356C6 17.3813 6.02925 17.5257 6.08607 17.6604C6.14289 17.795 6.22617 17.9174 6.33117 18.0205L11.0715 22.6743C11.5138 23.1086 12.2283 23.1086 12.6705 22.6743L24.6688 10.895C24.8809 10.6868 25 10.4044 25 10.11C25 9.81564 24.8809 9.5333 24.6688 9.32512C24.4568 9.11695 24.1692 9 23.8693 9C23.5695 9 23.2819 9.11695 23.0698 9.32512L11.8654 20.314Z"
                        fill="#223635"
                      />
                    </svg>
                  </>
                ) : (
                  <p className="third-count">3</p>
                )}
                <h3 className="rental_detail_heading">{_t("Rent payment")}</h3>
              </div>
              <div className="keyflex-sec">
                {step > 1 && (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="49"
                      viewBox="0 0 49 49"
                      fill="none"
                    >
                      <circle cx="24.5" cy="24.5" r="24" stroke="#A3EB64" />
                      <path
                        d="M28.6904 19.4302C27.6597 18.8358 26.4907 18.523 25.3008 18.5234C24.111 18.5238 22.9422 18.8374 21.9119 19.4325C20.8815 20.0277 20.026 20.8835 19.4312 21.914C18.8364 22.9445 18.5232 24.1135 18.5232 25.3033C18.5232 26.4932 18.8364 27.6621 19.4312 28.6926C20.026 29.7231 20.8815 30.5789 21.9119 31.1741C22.9422 31.7693 24.111 32.0828 25.3008 32.0832C26.4907 32.0836 27.6597 31.7708 28.6904 31.1764M17.3906 23.6078H23.0405M17.3906 26.9977H23.0405"
                        stroke="#253837"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M19.6499 15.5119C21.3669 14.5186 23.3161 13.997 25.2998 14C31.5407 14 36.5996 19.0589 36.5996 25.2998C36.5996 31.5407 31.5407 36.5996 25.2998 36.5996C19.0589 36.5996 14 31.5407 14 25.2998C14 23.2421 14.5503 21.311 15.5119 19.6499"
                        stroke="#253837"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    <p>
                      €{formData?.rent_amount} {_t("Payable on")}{" "}
                      {formData?.payment_date} {_t("of every month")}
                    </p>
                  </>
                )}
              </div>
            </div>
            {step > 1 && (
              <div>
                <a
                  href={undefined}
                  className="setNoInfoColor"
                  onClick={(e) => setStep(1)}
                >
                  {_t("Edit")}
                </a>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default RentalDate;
