import React, { useContext, useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import PhoneNumber from "libphonenumber-js";
import AuthContext from "../../../context/auth/AuthContext";
import "react-phone-number-input/style.css";
import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import axios from "axios";
import { options } from "joi";
import { Button, Col, Row } from "react-bootstrap";
import successicon from "../../../assets/img/successgif.gif";
import greentick from "../../../assets/img/greentick.png";
import cross from "../../../assets/img/cross-short.png";
import { init, setOptions } from "onfido-sdk-ui";
import { ImProfile } from "react-icons/im";
import TenantContext from "../../../context/tenant/TenantContext";
import { toast } from "react-toastify";
import { haveValue } from "../../../helper/common";
import { IoChevronBack } from "react-icons/io5";
import { sendUniversalMail } from "../../../services/sharedService";
import { useSnackbar } from "material-ui-snackbar-provider";
import AlreadyGuarantorOf from "../../../components/AlreadyGuarantorOf";
import SomeoneGuarantor from "../../../components/SomeoneGuarantor";

const EditFinancial = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { isLoggedIn, user, cardData, updateProfile, addCard, loadUser } =
    authContext;

  const snackbar = useSnackbar();

  const [financialData, setFinancialData] = useState({
    current_annual_income: 0,
    have_gurantor: "",
    gurantor_name: "",
    gurantor_email: "",
  });

  const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false);
  const [gurantorPresent, setGurantorPresent] = useState(false);

  console.log(user, "Check this that");
  useEffect(() => {
    const tempArr = {
      current_annual_income: user?.current_annual_income,
      have_gurantor: user?.have_gurantor,
      gurantor_name: user?.gurantor_name,
      gurantor_email: user?.gurantor_email,
    };
    setFinancialData(tempArr);
  }, []);

  const tenantContext = useContext(TenantContext);
  const { saveKycInfo, onfidoKyc, removeGuarantor } = tenantContext;

  const onSubmitFinancial = async (e) => {
    e.preventDefault();
    let userid = localStorage.getItem("saveduserid");
    const financialDatatemp = { ...financialData };
    if (
      financialDatatemp?.have_gurantor == "false" ||
      financialDatatemp?.have_gurantor == false
    ) {
      financialDatatemp.gurantor_name = "";
      financialDatatemp.gurantor_email = "";
    }
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/tenants/edit-financial/${userid}`,
        financialDatatemp,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        if (res.data?.alreadyGurantor) {
          setAlreadyGurantorOf(true);
          setGurantorPresent(true);
        } else {
          setFinancialData(financialDatatemp);
          if (
            haveValue(financialData?.gurantor_email) &&
            financialData?.gurantor_email != user?.gurantor_email
          ) {
            sendMail(financialData?.gurantor_email, "score");
          }
        }
        toast.success(_t("Details updated!"));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.error);
      });
  };

  const handleValChange = async (e) => {
    console.log(e.target.name, e.target.value);
    if (e.target.name == "gurantor_email")
      setFinancialData((old) => ({
        ...old,
        [e.target.name]: e.target.value.toLowerCase(),
      }));
    else
      setFinancialData((old) => ({ ...old, [e.target.name]: e.target.value }));
    // if (e.target.name == 'have_gurantor') {
    //     setFinancialData(old => ({ ...old, gurantor_name: '', gurantor_email: '' }))
    // }
  };

  const sendMail = (receiverEmail, emailType) => {
    let payload = {
      receiverEmail: receiverEmail,
      senderId: user?._id,
      emailType: emailType,
    };
    sendUniversalMail(payload)
      .then((res) => {
        snackbar.showMessage(_t("Email Sent"));
        console.log(res);
      })
      .catch((err) => {
        snackbar.showMessage(_t("Error Sending Mail"), err?.message);
        console.log(err);
      });
  };

  const handleRemove = async () => {
    await removeGuarantor(user?.gurantor_for?._id, user?._id);
    loadUser("tenant");
  };

  return (
    <>
      <div className="tabs-box-pattern">
        <div className="tabs-item d-block">
          <form onSubmit={onSubmitFinancial} className="position_relative">
            <div className="row edittitle blockelement">
              <div className="col-md-4 ">
                <button
                  type="button"
                  onClick={(e) => navigate("/tenant-profile")}
                  className="backbtn"
                >
                  <IoChevronBack />
                </button>
              </div>
              <div className="col-md-4">
                <h4>{_t("Edit Financial info")}</h4>
              </div>
              <div className="col-md-4">
                {/* <div className="row mt-3">
                  <div className="col-md-12"> */}
                <div className="btngroupbar">
                  <button type="submit" className="btnsubmit">
                    {_t("Save Changes")}
                  </button>
                </div>
                {/* </div>
                </div> */}
              </div>
            </div>
            <div
              className="row"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <h3 className="Property">{_t("Bank Details")}</h3>
              <div className="edit-profile row">
                <div className="edit-line col-6">
                  <h4>{_t("IBAN")}:</h4>
                  <input
                    className="custom-input"
                    type="text"
                    disabled
                    value={
                      user?.kyc_details?.userDataByProvider[0]?.accounts[0]
                        ?.iban
                    }
                  />
                </div>
                <div className="edit-line col-6">
                  <h4>{_t("Current Annual Income")}:</h4>
                  {/* <h3>{user?.current_annual_income}</h3> */}
                  <input
                    className="custom-input"
                    type="number"
                    placeholder="Current Annual Income"
                    name="current_annual_income"
                    onChange={(e) => handleValChange(e)}
                    value={financialData?.current_annual_income}
                  />
                </div>
              </div>
              {haveValue(user?.gurantor_for) ? (
                <>
                  <h3 className="Property">{_t("Guarantor Details")}</h3>
                  <div className="edit-profile row">
                    <div className="edit-profile row">
                      <div className="edit-line col-6">
                        <h4>{_t("Guarantor Of")}:</h4>
                        <h3>
                          {user?.gurantor_for?.fname}{" "}
                          {user?.gurantor_for?.lname}
                        </h3>
                      </div>
                      <div className="edit-line col-6">
                        <h4>{_t("Nationality")}:</h4>
                        <h3>{user?.gurantor_for?.nationality}</h3>
                      </div>
                      <div className="edit-line col-6">
                        <h4>{_t("Phone Number")}:</h4>
                        <h3>{user?.gurantor_for?.phone_number}</h3>
                      </div>
                      <div className="edit-line col-6">
                        <h4>{_t("Email")}:</h4>
                        <h3>{user?.gurantor_for?.email}</h3>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <button
                        className="btn-reject"
                        type="button"
                        onClick={handleRemove}
                      >
                        {_t("Remove Yourself as a Guarantor")}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h3 className="Property">{_t("Guarantor Details")}</h3>
                  <div className="edit-profile row">
                    <div className="edit-line col-6">
                      <h4>{_t("Do you have a Guarantor:")}:</h4>
                      {/* <select className="custom-input" name="have_gurantor" onChange={e => handleValChange(e)} value={(haveValue(financialData?.have_gurantor) && financialData?.have_gurantor == 'true') ? 'true' : 'false'}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select> */}
                      {console.log(
                        financialData?.have_gurantor,
                        "lahsdaksjdklajdkljd"
                      )}
                      <div className="select-radio">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="have_gurantor"
                            value="true"
                            onChange={handleValChange}
                            checked={
                              financialData?.have_gurantor == "true"
                                ? true
                                : false
                            }
                          />
                          <label className="form-check-label">
                            {_t("Yes")}
                          </label>
                        </div>
                        &nbsp; &nbsp;
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="have_gurantor"
                            value="false"
                            onChange={handleValChange}
                            checked={
                              financialData?.have_gurantor == "false" ||
                              !haveValue(financialData?.have_gurantor)
                                ? true
                                : false
                            }
                          />
                          <label className="form-check-label">{_t("No")}</label>
                        </div>
                      </div>
                    </div>
                    <div className="edit-line col-6">
                      <h4>{_t("Name Of Guarantor")}:</h4>
                      <input
                        type="text"
                        className="custom-input"
                        name="gurantor_name"
                        placeholder="Name Of Guarantor"
                        value={financialData?.gurantor_name}
                        onChange={(e) => handleValChange(e)}
                        disabled={
                          financialData?.have_gurantor == "false" ||
                          financialData?.have_gurantor == false ||
                          !haveValue(financialData?.have_gurantor)
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="edit-line col-6">
                      <h4>{_t("Email Of Guarantor")}:</h4>
                      <input
                        type="text"
                        className="custom-input"
                        name="gurantor_email"
                        placeholder="Email Of Guarantor"
                        value={financialData?.gurantor_email}
                        onChange={(e) => handleValChange(e)}
                        disabled={
                          financialData?.have_gurantor == "false" ||
                          financialData?.have_gurantor == false ||
                          !haveValue(financialData?.have_gurantor)
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      {alreadyGurantorOf && gurantorPresent == true && (
        <SomeoneGuarantor
          alreadyGurantorOf={alreadyGurantorOf}
          setAlreadyGurantorOf={setAlreadyGurantorOf}
          gurantorPresent={gurantorPresent}
          setGurantorPresent={setGurantorPresent}
        />
      )}
    </>
  );
};

export default EditFinancial;
