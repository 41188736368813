import { useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import constants from "../../../Utils/constants";
import { haveValue } from "../../../helper/common";
import TenantContext from "../../../context/tenant/TenantContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";

const TenantProvideDocument = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { uploadFileData, fetchTenantDocs, updateTenant } = tenantContext;
  const { user } = authContext;

  const [fileType, setFileType] = useState("");
  const [show, setShow] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [tenantDocsData, setTenantDocsData] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  });

  useEffect(() => {
    fetchFileData();
  }, []);

  useEffect(() => {
    console.log(
      tenantDocsData.length,
      "tenantDocsData.lengthtenantDocsData.lengthtenantDocsData.length"
    );
    if (tenantDocsData.length > 2) {
      console.log("CONDITION MET HERE");
      setSliderSettings({
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <BsFillArrowRightSquareFill />,
        prevArrow: <BsFillArrowLeftSquareFill />,
      });
    }
  }, [tenantDocsData]);

  const navigateHome = () => {
    navigate("/tenant");
  };

  const handleSelectChange = (e) => {
    setFileType(e.target.value);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("tenantDocs", files[i]);
    }
    formData.append("fileType", fileType);
    setFileData(formData);
  };

  const handleFileUpload = async () => {
    try {
      setUploadLoading(true);
      await uploadFileData(fileData);

      setFileData(null);
      setFileType("");
      await fetchFileData();
      handleClose();
      setUploadLoading(false);
    } catch (err) {
      setUploadLoading(false);
      console.log(err);
    }
  };

  const addRewardPoints = () => {
    const data = {
      reward_points:
        (user?.reward_points || 0) + +constants.DOCS_COMPLETE_POINTS,
    };
    updateTenant(data);
  };

  const fetchFileData = async () => {
    console.log("fetchFileData called");
    let userid = localStorage.getItem("saveduserid");
    const res = await fetchTenantDocs(userid);
    setTenantDocsData(res.data.success_res.tenantDocs);
  };

  const handleSubmitDocuments = async () => {
    updateTenant({ docs_uploaded: true })
      .then((res) => {
        addRewardPoints();
        setCurrentStep(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkNextButton = () => {
    if (!haveValue(user?.onfido_kyc_success) || !user?.onfido_kyc_success) {
      return (
        <button
          className="btn btnorange"
          onClick={(e) => navigate("/tenant/verifyId")}
        >
          {_t("Continue To Verify Id")}
        </button>
      );
    } else if (
      (!haveValue(user?.have_gurantor) ||
        !user?.have_gurantor ||
        user?.have_gurantor == "false") &&
      (!haveValue(user?.kyc_success) ||
        !user?.kyc_success ||
        user?.kyc_success == "false")
    ) {
      return (
        <button
          className="btn btnorange"
          onClick={(e) => navigate("/tenant/connectBank")}
        >
          {_t("Continue To Connect Bank")}
        </button>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {currentStep == 0 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a href={undefined} onClick={navigateHome}>
                    <img src="/images/back-btn-white.svg" alt="" />
                    <span>{_t("Go Back To Home")} </span>
                  </a>
                </div>
              </Container>
              <h3 className="step-form-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <circle cx="27" cy="27" r="27" fill="#253837"></circle>
                  <path
                    d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                    fill="#A3EB64"
                  ></path>
                </svg>
                <span>{_t("Provide Documents")}</span>
              </h3>
            </div>
            <div className="steps-wrapper">
              <h3 className="step-name">
                {_t(
                  "Verify your documentation to complete your Tenant Profile"
                )}
              </h3>
              <p className="step-sub-head">
                {_t("At least 3 documents are mandatory to upload")}
              </p>
              <div className="d-flex gap-2 align-items-center">
                <Form.Select
                  size="lg"
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                  value={fileType}
                  className="form-control"
                >
                  <option value="">{_t("Select")}</option>

                  {user?.employment_status == constants.student && (
                    <>
                      {/* <option value={constants.national_id}>
                        {_t("National Id")}
                      </option> */}
                      <option value={constants.ie_student}>
                        {_t("Student registration contract")}
                      </option>
                      <option value={constants.university_acceptance_letter}>
                        {_t("University Acceptance Letter")}
                      </option>
                      {/* <option value={constants.guarantor}>
                        {_t("Guarantor")}
                      </option>
                      <option value={constants.ie_student}>
                        {_t("Student registration contract")}
                      </option> */}
                    </>
                  )}

                  {user?.employment_status == constants.employed && (
                    <>
                      {/* <option value={constants.national_id}>
                        {_t("National Id")}
                      </option> */}
                      <option value={constants.employment_contract}>
                        {_t("Employment Contract")}
                      </option>
                      <option value={constants.payslips}>
                        {_t("Last 3 payslips")}
                      </option>
                      <option
                        value={constants.previous_working_life_certificate}
                      >
                        {_t("Working life certificate")}
                      </option>
                      <option value={constants.income_tax_return}>
                        {_t("Last annual Tax Report (Model 100)")}
                      </option>
                      <option value={constants.deduction_certificate}>
                        {_t("Deduction certificate")}
                      </option>
                      <option value={constants.public_appointment_certificate}>
                        {_t(
                          "Public appointment certificate (only if public employee)"
                        )}
                      </option>
                    </>
                  )}

                  {user?.employment_status == constants.self_employed &&
                    user?.is_freelancer == true && (
                      <>
                        {/* <option value={constants.national_id}>
                          {_t("National Id")}
                        </option> */}
                        <option value={constants.fractioned_tax_return}>
                          {_t("Last Fractionated Tax Report (Model 130)")}
                        </option>
                        {/* <option value={constants.self_employed_certificate}>
                          {_t("Self-Employed Registration Certificate")}
                        </option> */}
                        <option value={constants.income_tax_return}>
                          {_t("Last annual Tax Report (Model 100)")}
                        </option>
                        <option
                          value={constants.previous_working_life_certificate}
                        >
                          {_t("Working life certificate")}
                        </option>
                        <option value={constants.quarterly_vat}>
                          {_t("Last quarterly VAT tax (Form 303)")}
                        </option>
                      </>
                    )}

                  {user?.employment_status == constants.self_employed &&
                    user?.is_freelancer != true && (
                      <>
                        {/* <option value={constants.national_id}>
                          {_t("National Id")}
                        </option> */}
                        {/* <option value={constants.self_employed_certificate}>
                          {_t("Self-Employed Registration Certificate")}
                        </option> */}
                        <option
                          value={constants.previous_working_life_certificate}
                        >
                          {_t("Working life certificate")}
                        </option>
                        {/* <option value={constants.payslips}>
                          {_t("Last 3 payslips")}
                        </option> */}
                        <option value={constants.income_tax_return}>
                          {_t("Last annual Tax Report (Model 100)")}
                        </option>
                        <option value={constants.fractioned_tax_return}>
                          {_t("Last Fractionated Tax Report (Model 130)")}
                        </option>
                        <option value={constants.corporate_tax_return}>
                          {_t("Last year's Corporate Tax Return")}
                        </option>
                        <option value={constants.corporate_balance_sheet}>
                          {_t("Last year's Corporate balance sheet")}
                        </option>
                        <option value={constants.corporate_PL}>
                          {_t("Last year's Corporate P&L")}
                        </option>
                      </>
                    )}
                  {user?.employment_status == constants.retired && (
                    <>
                      <option value={constants.pension_certificate}>
                        {_t("Pension certificate")}
                      </option>
                      <option value={constants.last_revalorization_letter}>
                        {_t("Last revalorization letter")}
                      </option>
                    </>
                  )}
                  {
                    <option value="Other Documents">
                      {_t("Other Documents")}
                    </option>
                  }
                </Form.Select>
                <button
                  className="btn btnorange step-btn"
                  onClick={() => {
                    handleShow();
                  }}
                  disabled={haveValue(fileType) ? false : true}
                >
                  {_t("Upload")}
                </button>
              </div>
              <p>{tenantDocsData.length}/3</p>
              <Row className="card-listing">
                {console.log(
                  { ...sliderSettings },
                  "sliderSettingssliderSettingssliderSettings"
                )}
                <Slider {...sliderSettings}>
                  {tenantDocsData?.map((eachData, index) => (
                    <Col md={6} key={eachData?._id}>
                      <Card>
                        <div className="videoframe">
                          <iframe
                            src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                            scrolling="no"
                          />
                        </div>
                        <Card.Body>
                          <div className="listing-head">
                            <div className="head-block">
                              <div className="leftbartext">
                                <h5>{eachData?.type}</h5>
                                <p>
                                  {eachData?.file_name
                                    .split("-")
                                    .slice(1)
                                    .join("-")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Slider>
              </Row>
              <button
                className="btn btnorange step-btn"
                disabled={tenantDocsData.length < 3}
                onClick={handleSubmitDocuments}
              >
                {_t("Submit")}
              </button>
            </div>
            <Modal
              className="modal-uploadpdf upload-doc"
              show={show}
              onHide={handleClose}
            >
              <Modal.Header closeButton>
                <h5 className="modal-main-heading">{_t("Select Document")}</h5>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="formFile" className="formcontrolbar">
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                    multiple={
                      fileType != constants.income_tax_return &&
                      fileType != constants.ie_student
                    }
                    accept="application/pdf"
                  />
                  <Button
                    className="btn-green"
                    onClick={handleFileUpload}
                    disabled={
                      haveValue(fileData) && !uploadLoading ? false : true
                    }
                  >
                    {uploadLoading ? _t("Please Wait") : _t("Upload")}
                  </Button>
                </Form.Group>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
      {currentStep == 1 && (
        <>
          <div className="step-form-main-wrapper">
            <div class="d-flex justify-content-center align-items-center mb-5">
              <h3 className="step-form-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <circle cx="27" cy="27" r="27" fill="#253837"></circle>
                  <path
                    d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                    fill="#A3EB64"
                  ></path>
                </svg>
                <span>{_t("Provide Documents")}</span>
              </h3>
            </div>
            <div className="step-complete-reward steps-wrapper">
              <div className="rewards-points">
                <span className="points">50</span>
                <p>{_t("Reward Points")}</p>
              </div>
              <p className="greeting-text">{_t("Congratulations")}</p>
              <p className="points-info">
                {_t(
                  "You’ve uploaded your documentation to your profile info and have been rewarded with 50 points"
                )}
              </p>
              {checkNextButton()}
              <button className="step-go-home-btn" onClick={navigateHome}>
                {_t("Go Back home")}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TenantProvideDocument;
