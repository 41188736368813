import { useContext, useEffect, useState } from "react";
import RentalDetails from "./RentalDetails";
import RentalDepositOptions from "./RentalDepositOptions";
import RentalDate from "./RentalDate";
import useTranslator from "../../hooks/useTranslator";
import { useNavigate, useParams } from "react-router-dom";
import LandLordContext from "../../context/landlord/LandLordContext";
import { toast } from "react-toastify";
import constants from "../../Utils/constants";
import { haveValue } from "../../helper/common";
import AuthContext from "../../context/auth/AuthContext";
import TenantContext from "../../context/tenant/TenantContext";

const RentalSetup = (props) => {
  const navigate = useNavigate();
  const { _t } = useTranslator();

  const [step, setStep] = useState(0);
  const { assignedPropertyId } = useParams();
  const { formData, setFormData } = props;

  const landLordContext = useContext(LandLordContext);
  const { initiateAssignProperty } = landLordContext;
  const authContext = useContext(AuthContext);
  const { setLoading } = authContext;
  const tenantContext = useContext(TenantContext);
  const { checkUelzStatus, createTransactionLedger } = tenantContext;
  const queryParameters = new URLSearchParams(window.location.search);
  const paramSuccess = queryParameters.get("success");

  const handleNextStep = () => {
    setStep((prev) => prev++);
  };

  useEffect(() => {
    if (paramSuccess == true || paramSuccess == "true") {
      navigate(`/review/rental/${formData?._id}`);
    } else if (paramSuccess == false || paramSuccess == "false") {
      toast.error(_t("Payment Failed!"));
    }
  }, []);

  //   useEffect(() => {
  //     const existingScript = document.querySelector(
  //       'script[src="https://uelzpay-widget-cdn-demo.vercel.app/v1.0.0/uelz-widget.js?uelz-api-key=clv3lz7bw000xnh01gy64vwsy&uelz-api-url=https://widget.demo.uelzpay.com"]'
  //     );

  //     if (existingScript) {
  //       // existingScript.parentNode.removeChild(existingScript); // Remove the existing script element
  //       window.location.reload();
  //     }

  //     setTimeout(() => {
  //       var script = document.createElement("script");
  //       script.src =
  //         "https://uelzpay-widget-cdn-demo.vercel.app/v1.0.0/uelz-widget.js?uelz-api-key=clv3lz7bw000xnh01gy64vwsy&uelz-api-url=https://widget.demo.uelzpay.com";
  //       script.onload = function () {
  //         console.log("Script loaded successfully.");
  //       };
  //       script.onerror = function () {
  //         console.error("Failed to load script.");
  //       };
  //       document.getElementById("uelzpayWrapper").appendChild(script);
  //     }, 200);
  //   }, []);

  const checkElementOnInterval = () => {
    var intervalId;

    intervalId = setInterval(() => {
      const checkElement = document.querySelector(
        "#uelz-widget-modal #success-page"
      );
      if (haveValue(checkElement)) {
        clearInterval(intervalId);
        setTimeout(() => {
          document
            .querySelector("#uelz-widget-modal .modal-header img")
            .click();
          checkSuccessPayload();
        }, 2000);
      }
    }, 500);
  };

  const checkSuccessPayload = () => {
    setLoading(true);
    checkUelzStatus(formData?._id)
      .then((res) => {
        if (res?.success_res?.data?.status == constants.COMPLETED) {
          setLoading(false);
          // navigate(`/awarded/property/${formData?._id}?confirmation=true`)
          navigate(`/review/rental/${formData?._id}`);
        } else {
          checkSuccessPayload();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleNavigate = async () => {
    createTransactionLedger(assignedPropertyId)
      .then((res) => {
        if (res.success) {
          window.location.replace(res.success_res.session.url);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // navigate(`/review/rental/${formData?._id}`);
  };

  return (
    <>
      <div className="foursteps-sec">
        <RentalDetails
          formData={formData}
          step={step}
          handleNextStep={handleNextStep}
          setStep={setStep}
        />

        <RentalDepositOptions
          formData={formData}
          step={step}
          handleNextStep={handleNextStep}
          setStep={setStep}
          setFormData={setFormData}
        />

        <RentalDate
          formData={formData}
          step={step}
          handleNextStep={handleNextStep}
          setStep={setStep}
          setFormData={setFormData}
        />

        <div className="three-rentpayment-sec">
          <div className="skorprotect-sec">
            {step == 2 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <circle cx="16" cy="16" r="16" fill="#7F7F7F" />
                <path
                  d="M11.8654 20.314L7.93019 16.4506C7.71814 16.2425 7.43055 16.1255 7.13068 16.1255C6.8308 16.1255 6.54321 16.2425 6.33117 16.4506C6.11912 16.6588 6 16.9412 6 17.2356C6 17.3813 6.02925 17.5257 6.08607 17.6604C6.14289 17.795 6.22617 17.9174 6.33117 18.0205L11.0715 22.6743C11.5138 23.1086 12.2283 23.1086 12.6705 22.6743L24.6688 10.895C24.8809 10.6868 25 10.4044 25 10.11C25 9.81564 24.8809 9.5333 24.6688 9.32512C24.4568 9.11695 24.1692 9 23.8693 9C23.5695 9 23.2819 9.11695 23.0698 9.32512L11.8654 20.314Z"
                  fill="white"
                />
              </svg>
            ) : (
              <p className="third-count">4</p>
            )}

            <h3 className="rental_detail_heading">{_t("Skor Protect")}</h3>
          </div>
          <div>
            <p className="comingsoon-content"> {_t("Coming Soon")} </p>
          </div>
        </div>
        {/* <button class="uelz-button uelz-button-styles"
                    id="uelz-button"
                    // className="btn btngreen"
                    style={{
                        padding: "14px 55px",
                        borderRadius: "12px",
                        fontWeight: 500,
                        fontSize: "15px",
                        lineHeight: "24px",
                        textAlign: "center",
                        background: "#A3EB64",
                        color: "#253837",
                        height: "unset",
                        width: "auto",
                        display: "none"
                    }}
                    // disabled={step != 2}
                    data-service-name={constants.RENT}
                    // data-plan-name="monthlyRentTest1"
                    data-plan-name="dailyRentTest1"
                    data-plan-description={`monthly rent for awarded property`}
                    data-plan-amount={formData?.deposit_type == constants.SKOR_DEPOSIT ? +formData?.rent_amount + +formData?.monthly_sub_amount : formData?.rent_amount}
                    // data-plan-amount={500}
                    // data-plan-amount={formData?.rent_amount}
                    data-plan-currency="EUR"
                    data-plan-type="Subscription"
                    // data-plan-frequency="month"
                    data-plan-frequency="day"
                    // data-plan-payment-day={payment_date}
                    // data-type-subscription="variable"
                    // data-future-charge-action="last_charge"
                    // data-consume-units={amountDeduct}

                    data-customer-email={formData?.tenant_id?.email}
                    data-customer-cif={haveValue(formData?.tenant_id?.onfido_user_kyc?.onfido_doc_data?.personal_number) ? formData?.tenant_id?.onfido_user_kyc?.onfido_doc_data?.personal_number : haveValue(formData?.tenant_id?.onfido_user_kyc?.onfido_doc_data?.document_number) ? formData?.tenant_id?.onfido_user_kyc?.onfido_doc_data?.document_number : null}
                    data-name={formData?.tenant_id?.fname}
                    data-surname={formData?.tenant_id?.lname}
                    data-external-customer-id={formData?.tenant_id?._id}

                    onClick={checkElementOnInterval}
                >
                    {_t("Confirm & Set Up Payment")}
                </button> */}
        {step == 2 && (
          <button
            className="btn btngreen  complete-pay"
            onClick={handleNavigate}
          >
            {_t("Complete Payment")}
          </button>
        )}
      </div>
    </>
  );
};

export default RentalSetup;
