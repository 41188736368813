import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../../hooks/useTranslator";
import { haveValue } from "../../helper/common";
import { useContext, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import AuthContext from "../../context/auth/AuthContext";
import { ProgressBar } from "react-bootstrap";

const TenantSkor = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const progressColor = "#F27833";
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [ieScore, setIeScore] = useState(0);

  useEffect(() => {
    var temp = 0;
    if (user?.profile_completed) {
      temp += 25;
    }
    if (user?.docs_uploaded) {
      temp += 25;
    }
    if (user?.onfido_kyc_success) {
      temp += 25;
    }
    if (user?.gurantor_id?.paymentScores || user?.paymentScores) {
      temp += 25;
    }

    setProfileCompletion(temp);
    calculateIeScore();
  }, [user]);

  useEffect(() => {
    let progressbar = document.querySelector(
      ".CircularProgressbar .CircularProgressbar-path"
    );
    if (
      document.querySelector(".retal-score-meter .CircularProgressbar-path")
    ) {
      document
        .querySelector(".retal-score-meter .CircularProgressbar-path")
        .classList.add("rent-score");
      document
        .querySelector(".CircularProgressbar")
        .insertBefore(
          document.querySelector(
            ".retal-score-meter .CircularProgressbar-path"
          ),
          progressbar
        );
    }
  }, []);

  const navigateConnectBank = () => {
    navigate("/tenant/connectBank");
  };

  const getGradient = (value) => {
    return `linear-gradient(to right, red ${
      value * 0.5
    }%, yellow ${value}%, green ${value}%)`;
  };

  const calculateIeScore = () => {
    var totalScore = (user?.paymentScores?.creditScore?.value || 0) / 2;

    if (user?.ie_verified && user?.is_university) {
      if (totalScore >= 600) {
        totalScore += 200;
      } else {
        totalScore += 400;
      }
    }
    if (totalScore > 850) {
      totalScore = 850;
    }
    setIeScore(totalScore);
  };

  return (
    <>
      {haveValue(user?.gurantor_id?.paymentScores?.creditScore?.value) ||
      haveValue(user?.paymentScores?.creditScore?.value) ||
      (user?.ie_verified && user?.is_university) ? (
        <div className="tenant-skore pending-steps-wrapper">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h3 className="skore-heading mb-0">Skor</h3>
            <Link to="/home">
              {_t("View your profile")}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
              >
                <path
                  d="M1 1L7.54748 7.00483C7.69083 7.13486 7.80465 7.2897 7.88233 7.46036C7.96 7.63103 8 7.81414 8 7.99909C8 8.18403 7.96 8.36714 7.88233 8.53781C7.80465 8.70848 7.69084 8.86331 7.54748 8.99334L1 15"
                  stroke="#7A7A7A"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="border-wrapper">
                <svg style={{ height: 0 }}>
                  <defs>
                    <linearGradient
                      id="progressGradient"
                      gradientTransform="rotate(90)"
                    >
                      <stop offset="0%" stopColor={"red"} />
                      <stop offset="50%" stopColor={"yellow"} />
                      <stop offset="100%" stopColor={"green"} />
                    </linearGradient>
                  </defs>
                </svg>
                <CircularProgressbar
                  width="75%"
                  maxValue="1000"
                  value={
                    haveValue(
                      user?.gurantor_id?.paymentScores?.creditScore?.value
                    )
                      ? user?.gurantor_id?.paymentScores?.creditScore?.value <
                        800
                        ? user?.gurantor_id?.paymentScores?.creditScore?.value
                        : 800
                      : user?.ie_verified && user?.is_university
                      ? ieScore < 800
                        ? ieScore
                        : 800
                      : user?.paymentScores?.creditScore?.value < 800
                      ? user?.paymentScores?.creditScore?.value
                      : 800
                  }
                  text={`${
                    haveValue(
                      user?.gurantor_id?.paymentScores?.creditScore?.value
                    )
                      ? user?.gurantor_id?.paymentScores?.creditScore?.value +
                        user?.rental_skor
                      : user?.ie_verified && user?.is_university
                      ? ieScore + user?.rental_skor
                      : user?.paymentScores?.creditScore?.value +
                        user?.rental_skor
                  }`}
                  circleRatio={0.5}
                  strokeWidth={6}
                  styles={buildStyles({
                    textColor: `${progressColor}`,
                    pathColor: `${progressColor}`,
                    rotation: 0.75,
                    strokeLinecap: "round",
                  })}
                />
                <div className="retal-score-meter d-none">
                  <CircularProgressbar
                    width="75%"
                    maxValue="1000"
                    value={
                      haveValue(
                        user?.gurantor_id?.paymentScores?.creditScore?.value
                      )
                        ? (user?.gurantor_id?.paymentScores?.creditScore
                            ?.value < 800
                            ? user?.gurantor_id?.paymentScores?.creditScore
                                ?.value
                            : 800) +
                          (user?.rental_skor < 200 ? user?.rental_skor : 200)
                        : user?.ie_verified && user?.is_university
                        ? (ieScore < 800 ? ieScore : 800) +
                          (user?.rental_skor < 200 ? user?.rental_skor : 200)
                        : (user?.paymentScores?.creditScore?.value < 800
                            ? user?.paymentScores?.creditScore?.value
                            : 800) +
                          (user?.rental_skor < 200 ? user?.rental_skor : 200)
                    }
                    // value={800}
                    text={`${
                      haveValue(
                        user?.gurantor_id?.paymentScores?.creditScore?.value
                      )
                        ? user?.gurantor_id?.paymentScores?.creditScore?.value +
                          user?.rental_skor
                        : user?.paymentScores?.creditScore?.value +
                          user?.rental_skor
                    }`}
                    circleRatio={0.5}
                    strokeWidth={6}
                    styles={buildStyles({
                      textColor: `${progressColor}`,
                      pathColor: `${progressColor}`,
                      rotation: 0.75,
                      strokeLinecap: "round",
                    })}
                  />
                </div>
                <div className="name-box-new">
                  <h3 className="score-name">
                    {haveValue(
                      user?.gurantor_id?.paymentScores?.creditScore?.value
                    )
                      ? user?.gurantor_id?.kyc_details?.userDataByProvider[0]
                          ?.identity?.name
                      : user?.kyc_details?.userDataByProvider[0]?.identity
                          ?.name}
                    {"*"}
                  </h3>
                  <ul className="score-textgray">
                    <span>*{_t("Bank Account Used For Scoring")} </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="border-wrapper score-card">
                <h3 className="score-card-head">{_t("Score Details")}:</h3>
                <div className="progress-bar-wrapper">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="progress-head">{_t("Financial Skor")}</p>
                    <p className="progress-score">
                      {haveValue(
                        user?.gurantor_id?.paymentScores?.creditScore?.value
                      )
                        ? user?.gurantor_id?.paymentScores?.creditScore?.value
                        : user?.ie_verified && user?.is_university
                        ? ieScore
                        : user?.paymentScores?.creditScore?.value}
                    </p>
                  </div>
                  <div className="progress-bar-inner-wrapper">
                    <div className="progress">
                      <span
                        className="progress-color"
                        style={{
                          width: haveValue(
                            user?.gurantor_id?.paymentScores?.creditScore?.value
                          )
                            ? `${
                                100 -
                                (user?.gurantor_id?.paymentScores?.creditScore
                                  ?.value > 800
                                  ? 800
                                  : user?.gurantor_id?.paymentScores
                                      ?.creditScore?.value) /
                                  8
                              }%`
                            : user?.ie_verified && user?.is_university
                            ? `${100 - (ieScore > 800 ? 800 : ieScore) / 8}%`
                            : `${
                                100 -
                                (user?.paymentScores?.creditScore?.value > 800
                                  ? 800
                                  : user?.paymentScores?.creditScore?.value) /
                                  8
                              }%`,
                        }}
                      ></span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="score-limit">0</span>
                      <span className="score-limit">800</span>
                    </div>
                  </div>
                </div>
                <div className="progress-bar-wrapper">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="progress-head">{_t("Rental Skor")}</p>
                    <p className="progress-score rent-score-box">
                      {user?.rental_skor}
                    </p>
                  </div>
                  <div className="progress-bar-inner-wrapper">
                    <div className="progress">
                      <span
                        className="progress-simple-color"
                        style={{ width: `${user?.rental_skor / 2}%` }}
                      ></span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="score-limit">0</span>
                      <span className="score-limit">200</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <button
                className="btn btngreen d-inline-flex gap-2 align-items-center"
                onClick={(e) => navigate("/profile-shared")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                >
                  <path
                    d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 6 15 6C16.66 6 18 4.66 18 3C18 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C1.34 7 0 8.34 0 10C0 11.66 1.34 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.35C12.11 16.56 12.08 16.78 12.08 17C12.08 18.61 13.39 19.92 15 19.92C16.61 19.92 17.92 18.61 17.92 17C17.92 15.39 16.61 14.08 15 14.08Z"
                    fill="black"
                  />
                </svg>
                {_t("Share Profile With Landlord")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="pending-steps-wrapper skore-details">
          <h3 className="skore-details-heading">Skor</h3>
          <div className="d-flex align-items-streach skore-dummmy">
            <div className="skore-image-wrapper">
              <img src="/images/fetchingBank.svg" alt="" />
            </div>
            <div className="skore-details-content">
              <img src="/images/home-icon.svg" alt="" />
              <h3 className="skore-detail-heading">{_t("Financial Check")}</h3>
              <p className="skor-details-desc">
                {_t(
                  "Connecting your bank account allow you to reduce your entry deposit, but also boosts your credibility with landlords. In fact, 70% of landlords prefer profiles with bank verification."
                )}
              </p>
              <div class="skore-details-points">
                <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                <span>50 {_t("Points")}</span>
              </div>
              <button
                onClick={navigateConnectBank}
                className={`btn ${
                  (user?.have_gurantor == "true" &&
                    !haveValue(user?.gurantor_id?.paymentScores)) ||
                  ((user?.kyc_success || user?.kyc_success == "true") &&
                    !haveValue(user?.paymentScores))
                    ? "btnorange"
                    : "btngreen"
                } complete-btn`}
              >
                {(user?.have_gurantor == "true" &&
                  !haveValue(user?.gurantor_id?.paymentScores)) ||
                ((user?.kyc_success || user?.kyc_success == "true") &&
                  !haveValue(user?.paymentScores))
                  ? _t("View Progress")
                  : _t("Complete")}
              </button>
            </div>
          </div>
          {profileCompletion > 25 && (
            <div className="col-12">
              <button
                className="btn btngreen d-inline-flex gap-2 align-items-center"
                onClick={(e) => navigate("/profile-shared")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                >
                  <path
                    d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 6 15 6C16.66 6 18 4.66 18 3C18 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C1.34 7 0 8.34 0 10C0 11.66 1.34 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.35C12.11 16.56 12.08 16.78 12.08 17C12.08 18.61 13.39 19.92 15 19.92C16.61 19.92 17.92 18.61 17.92 17C17.92 15.39 16.61 14.08 15 14.08Z"
                    fill="black"
                  />
                </svg>
                {_t("Share Profile With Landlord")}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TenantSkor;
